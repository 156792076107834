import React, { useCallback } from 'react';
import ParagraphPrimary from './ParagraphPrimary';

type RadioButtonProps<T> = {
  label: string;
  isSelected: boolean;
  isDisabled?: boolean;
  value: T;
  onRadioClick: (value: T) => void;
  className?: string;
};

const RadioButton = <T,>({
  label,
  isSelected,
  value,
  onRadioClick,
  isDisabled,
  className = '',
}: RadioButtonProps<T>) => {
  const clickRadioButton = useCallback(() => {
    onRadioClick(value);
  }, [onRadioClick, value]);

  return (
    <button
      onClick={clickRadioButton}
      className={`flex bg-transparent border-0 p-0 ${className}`}
      disabled={isDisabled}
    >
      <div className="rounded-full border-2 border-primary h-5 w-5 p-1">
        {isSelected && (
          <div className="rounded-full bg-primary h-full w-full" />
        )}
      </div>
      <ParagraphPrimary className="ml-4">{label}</ParagraphPrimary>
    </button>
  );
};

export default RadioButton;
