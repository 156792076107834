import {
  GetCompanyFeedbackRequest,
  GetDataLakeFeedbacksRequest,
  GetFeedbacksRequest,
  GetFeedbacksStatsRequest,
} from '../../api/feedback/feedback.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation, useAppQuery } from './utils';

export const useGetCompanyFeedbackMutation = () => {
  const { feedbackApi } = useApi();
  const mutation = useAppMutation((req: GetCompanyFeedbackRequest) =>
    feedbackApi.getCompanyFeedbacks(req)
  );
  return mutation;
};

export const useGetFeedbacksMutation = () => {
  const { feedbackApi } = useApi();
  const mutation = useAppMutation((req: GetFeedbacksRequest) => {
    return feedbackApi.getFeedbacks(req);
  });
  return mutation;
};

export const useGetDataLakeFeedbacksQuery = (
  req: GetDataLakeFeedbacksRequest,
  isEnabled = true
) => {
  const { feedbackApi } = useApi();
  const query = useAppQuery(
    [InvalidationTag.Feedbacks, req],
    async ({ queryKey }) => {
      const requestParams = queryKey[1] as GetDataLakeFeedbacksRequest;
      if (!requestParams.companyId || !requestParams.from || !requestParams.to)
        return undefined;
      return await feedbackApi.getDataLakeFeedbacks(requestParams);
    },
    {
      enabled: isEnabled,
    }
  );
  return query;
};

export const useGetFeedbackStatsQuery = (
  req: GetFeedbacksStatsRequest,
  isEnabled = true
) => {
  const { feedbackApi } = useApi();
  const query = useAppQuery(
    [InvalidationTag.FeedbacksStats, req],
    async ({ queryKey }) => {
      const requestParams = queryKey[1] as GetFeedbacksStatsRequest;
      if (!requestParams.companyId || !requestParams.from || !requestParams.to)
        return undefined;
      return await feedbackApi.getCompanyStats(requestParams);
    },
    {
      enabled: isEnabled,
    }
  );
  return query;
};
