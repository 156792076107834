import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18n from 'i18n-js';
import it from './values/it';
import en from './values/en';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import useSystemLanguage from './useSystemLanguage';
import { LocalizationProvider as MUILocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FC } from '../types/general.types';

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

export enum SupportedLocale {
  It = 'it',
  En = 'en',
}

export const languages = { it, en };

export type DeepKeys<T, S extends string> = T extends object
  ? S extends `${infer I1}.${infer I2}`
    ? I1 extends keyof T
      ? `${I1}.${DeepKeys<T[I1], I2>}`
      : keyof T & string
    : S extends keyof T
    ? `${S}`
    : keyof T & string
  : '';

export type LocalizationKeys = keyof typeof it;
export type CallbackTranslation = <S extends string>(
  scope: DeepKeys<typeof it, S>,
  options?: Object
) => string;
export type CallbackSetTranslation = (language: SupportedLocale) => void;

export const localeManager = <S extends string>(
  scope: DeepKeys<typeof it, S>,
  newOptions?: Object
): string => {
  const options = newOptions || { locale: SupportedLocale.It };
  return i18n.t(scope, options || {});
};

interface LocalizationContextProps {
  t: CallbackTranslation;
  setLocale: CallbackSetTranslation;
  currentLocale: SupportedLocale;
}

i18n.fallbacks = true;
i18n.translations = { it, en };

export const LocalizationContext = createContext<LocalizationContextProps>({
  t: (scope, options = {}) =>
    i18n.t(scope, { it: SupportedLocale.It, ...options }),
  setLocale: () => {},
  currentLocale: SupportedLocale.It,
});

export const LocalizationProvider: FC = ({ children }) => {
  const [locale, setLocale] = useState<SupportedLocale>(SupportedLocale.It);
  const { language: sysLanguage, error: langRetriveError } =
    useSystemLanguage();

  const [dayjsTranslationCompleted, setDayjsTranslationCompleted] =
    useState<boolean>(false);

  const t = useMemo((): CallbackTranslation => {
    return (scope, options) => i18n.t(scope, { locale, ...options });
  }, [locale]);

  useEffect(() => {
    if (langRetriveError || !sysLanguage) {
      setLocale(SupportedLocale.En);
    } else {
      const currentLanguage = sysLanguage.split('-')[0];
      if (currentLanguage === SupportedLocale.It) {
        setLocale(SupportedLocale.It);
      } else {
        setLocale(SupportedLocale.En);
      }
    }
  }, [langRetriveError, sysLanguage]);

  useEffect(() => {
    updateLocaleDaysTranslations(locale, t);
    dayjs.locale(locale);
    setDayjsTranslationCompleted(true);
  }, [locale, t]);

  const setAppLocale = useCallback((language: SupportedLocale) => {
    i18n.locale = language;
    setLocale(language);
  }, []);

  return (
    <MUILocalizationProvider dateAdapter={AdapterMoment}>
      <LocalizationContext.Provider
        value={{
          t,
          setLocale: setAppLocale,
          currentLocale: locale,
        }}
      >
        {dayjsTranslationCompleted ? children : null}
      </LocalizationContext.Provider>
    </MUILocalizationProvider>
  );
};

export function updateLocaleDaysTranslations(
  locale: string,
  t: CallbackTranslation
) {
  dayjs.updateLocale(locale, {
    weekdays: [
      t('days.sunday'),
      t('days.monday'),
      t('days.tuesday'),
      t('days.wednesday'),
      t('days.thursday'),
      t('days.friday'),
      t('days.saturday'),
    ],
  });
}
