import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { ICompanyActionApi } from './ICompanyActionApi';
import {
  GetCompanyActionsRequest,
  CompanyAction,
  CreateCompanyActionRequest,
  UpdateCompanyActionRequest,
  DeleteCompanyActionRequest,
  GetCompanyActionsResponse,
  CreateCompanyActionResponse,
  UpdateCompanyActionResponse,
} from './companyAction.models';

export class CompanyActionApi implements ICompanyActionApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getCompanyActions = async ({
    actionLevel,
    companyId,
    entityId,
    from,
    to,
  }: GetCompanyActionsRequest): Promise<CompanyAction[]> => {
    const res = await this.httpClient.get<GetCompanyActionsResponse>(
      `/core/company/${companyId}/action/level/${actionLevel}/entity/${entityId}`,
      {
        params: {
          from,
          to,
        },
        isAuthorized: true,
      }
    );
    return res.items;
  };

  public readonly createCompanyAction = async ({
    actionLevel,
    body,
    companyId,
    entityId,
    heading,
    implementationDate,
    type,
  }: CreateCompanyActionRequest): Promise<CompanyAction> => {
    const res = await this.httpClient.post<CreateCompanyActionResponse>(
      `/core/company/${companyId}/action`,
      {
        body: {
          actionLevel,
          type,
          body,
          entityId,
          heading,
          implementationDate,
        },
        isAuthorized: true,
      }
    );
    return res.item;
  };

  public readonly updateCompanyAction = async ({
    body,
    companyId,
    heading,
    implementationDate,
    actionId,
  }: UpdateCompanyActionRequest): Promise<CompanyAction> => {
    const res = await this.httpClient.put<UpdateCompanyActionResponse>(
      `/core/company/${companyId}/action/${actionId}`,
      {
        body: {
          body,
          heading,
          implementationDate,
        },
        isAuthorized: true,
      }
    );
    return res.item;
  };

  public readonly deleteCompanyAction = async ({
    companyId,
    actionId,
  }: DeleteCompanyActionRequest): Promise<void> => {
    await this.httpClient.delete<void>(
      `/core/company/${companyId}/action/${actionId}`,
      { isAuthorized: true }
    );
  };
}
