import { createNotImplementedMethod } from '../utils';
import {
  GetWhoAmIRequest,
  GetWhoAmIResponse,
  UpdateProfileRequest,
  UserDetails,
} from './user.models';

export interface IUserApi {
  readonly getWhoAmI: (req: GetWhoAmIRequest) => Promise<GetWhoAmIResponse>;
  readonly getMy: () => Promise<UserDetails>;
  readonly updateMy: (req: UpdateProfileRequest) => Promise<void>;
}

export class NotImplementedUserApi implements IUserApi {
  public readonly getWhoAmI = createNotImplementedMethod();
  public readonly getMy = createNotImplementedMethod();
  public readonly updateMy = createNotImplementedMethod();
}
