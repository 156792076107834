import { createSlice } from '@reduxjs/toolkit';
import {
  addFullPageLoaderHandler,
  addLoaderHandler,
  removeLoaderHandler,
  removeFullPageLoaderHandler,
  setIsAuthorizedHandler,
  setIsInitializedHandler,
  setIsInErrorHandler,
  resetGeneralToDefaultHandler,
} from './general.reducer';

export type GeneralState = {
  fullPageLoaders: string[];
  loaders: string[];
  isInError: boolean;
  isAuthorized: boolean | undefined;
  isInitialized: boolean;
};

export const generalInitialState: GeneralState = {
  fullPageLoaders: [],
  isInError: false,
  loaders: [],
  isAuthorized: undefined,
  isInitialized: false,
};

const GENERAL_SLICE = 'generalSlice';

const generalSlice = createSlice({
  name: GENERAL_SLICE,
  initialState: generalInitialState,
  reducers: {
    addFullPageLoader: addFullPageLoaderHandler,
    removeFullPageLoader: removeFullPageLoaderHandler,
    addLoader: addLoaderHandler,
    removeLoader: removeLoaderHandler,
    setIsAuthorized: setIsAuthorizedHandler,
    setIsInitialized: setIsInitializedHandler,
    setIsInError: setIsInErrorHandler,
    resetGeneralToDefault: resetGeneralToDefaultHandler,
  },
});

export const {
  addFullPageLoader,
  removeFullPageLoader,
  addLoader,
  removeLoader,
  setIsAuthorized,
  setIsInitialized,
  setIsInError,
  resetGeneralToDefault,
} = generalSlice.actions;
export const generalReducer = generalSlice.reducer;
