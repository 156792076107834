import React from 'react';
import { FC } from '../types/general.types';

type TitleProps = {
  className?: string;
};

const Title: FC<TitleProps> = ({ className, children }) => {
  return (
    <h1 className={`text-3xl text-fake-black font-normal ${className}`}>
      {children}
    </h1>
  );
};

export default Title;
