import * as React from 'react';

export type TextAreaProps = {
  value?: string;
  error?: string;
  placeholder?: string;
  className?: string;
  rows?: number;
  isDisabled?: boolean;
  onValueChange?: (val: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const TextArea: React.FC<TextAreaProps> = ({
  className = '',
  placeholder,
  error,
  value,
  rows = 4,
  isDisabled,
  onChange,
  onValueChange,
  onFocus,
  onBlur,
}) => {
  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  return (
    <>
      <textarea
        value={value}
        placeholder={placeholder}
        rows={rows}
        disabled={isDisabled}
        className={`bg-card border-0 rounded-md p-3 placeholder-grey text-fake-black outline-0 ${className}`}
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {error && <p className="text-danger text-sm mt-1">{error}</p>}
    </>
  );
};

export default TextArea;
