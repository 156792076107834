import React from 'react';
import { FC } from '../types/general.types';

type TextButtonProps = {
  onClick?: () => void;
};

const TextButton: FC<TextButtonProps> = ({ children, onClick }) => {
  return (
    <a role="button" onClick={onClick} className="text-secondary font-medium">
      {children}
    </a>
  );
};

export default TextButton;
