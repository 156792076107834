import { createNotImplementedMethod } from '../utils';
import {
  CompanyAction,
  CreateCompanyActionRequest,
  DeleteCompanyActionRequest,
  GetCompanyActionsRequest,
  UpdateCompanyActionRequest,
} from './companyAction.models';

export interface ICompanyActionApi {
  readonly getCompanyActions: (
    req: GetCompanyActionsRequest
  ) => Promise<CompanyAction[]>;
  readonly createCompanyAction: (
    req: CreateCompanyActionRequest
  ) => Promise<CompanyAction>;
  readonly updateCompanyAction: (
    req: UpdateCompanyActionRequest
  ) => Promise<CompanyAction>;
  readonly deleteCompanyAction: (
    req: DeleteCompanyActionRequest
  ) => Promise<void>;
}

export class NotImplementedCompanyActionApi implements ICompanyActionApi {
  readonly getCompanyActions = createNotImplementedMethod();
  readonly createCompanyAction = createNotImplementedMethod();
  readonly updateCompanyAction = createNotImplementedMethod();
  readonly deleteCompanyAction = createNotImplementedMethod();
}
