import { createNotImplementedMethod } from '../utils';
import {
  Achievement,
  Challenge,
  GetAchievementsRequest,
  GetChallengeRequest,
  GetCompanyLeaderboardPositionRequest,
  GetCompanyLeaderboardRequest,
  GetHistoryChallengesRequest,
  LeaderboardElement,
  MyLeaderboardPosition,
} from './gamification.models';

export interface IGamificationApi {
  readonly getCompanyLeaderboardPosition: (
    req: GetCompanyLeaderboardPositionRequest
  ) => Promise<MyLeaderboardPosition>;

  readonly getCompanyLeaderboard: (
    req: GetCompanyLeaderboardRequest
  ) => Promise<LeaderboardElement[]>;

  readonly getChallenge: (req: GetChallengeRequest) => Promise<Challenge>;

  readonly getHistoryChallenges: (
    req: GetHistoryChallengesRequest
  ) => Promise<Challenge[]>;

  readonly getAchievements: (
    req: GetAchievementsRequest
  ) => Promise<Achievement[]>;
}

export class NotImplementedGamificationApi implements IGamificationApi {
  public readonly getCompanyLeaderboardPosition = createNotImplementedMethod();
  public readonly getCompanyLeaderboard = createNotImplementedMethod();
  public readonly getChallenge = createNotImplementedMethod();
  public readonly getHistoryChallenges = createNotImplementedMethod();
  public readonly getAchievements = createNotImplementedMethod();
}
