import * as React from 'react';

type LanguageResponse = {
  language: string | undefined;
  error: Error | null;
};

const useSystemLanguage = (): LanguageResponse => {
  const [response, setResponse] = React.useState<LanguageResponse>({
    language: undefined,
    error: null,
  });

  React.useEffect(() => {
    try {
      // Get browser language
      let browserLanguage = navigator.language;

      // Fallback to system language
      if (!browserLanguage) {
        if (
          window.navigator.languages &&
          window.navigator.languages.length > 0
        ) {
          browserLanguage = window.navigator.languages[0];
        }
      }

      setResponse({ language: browserLanguage, error: null });
    } catch (error) {
      setResponse({ language: undefined, error: error as Error });
    }
  }, []);

  return response;
};

export default useSystemLanguage;
