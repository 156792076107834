import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import {
  GetAchievementsRequest,
  GetAchievementsResponse,
  GetChallengeRequest,
  GetChallengeResponse,
  GetChallengesResponse,
  GetCompanyLeaderboardPositionRequest,
  GetCompanyLeaderboardPositionResponse,
  GetCompanyLeaderboardRequest,
  GetCompanyLeaderboardResponse,
  GetHistoryChallengesRequest,
} from './gamification.models';
import { IGamificationApi } from './IGamificationApi';

export class GamificationApi implements IGamificationApi {
  constructor(private httpClient: IHttpClient) {}

  public readonly getCompanyLeaderboardPosition = async ({
    companyId,
    month,
  }: GetCompanyLeaderboardPositionRequest) => {
    const res =
      await this.httpClient.get<GetCompanyLeaderboardPositionResponse>(
        `/gamification/leaderboard/company/${companyId}/position`,
        {
          isAuthorized: true,
          params: {
            month,
          },
        }
      );

    return res.items;
  };

  public readonly getCompanyLeaderboard = async ({
    companyId,
    month,
  }: GetCompanyLeaderboardRequest) => {
    const res = await this.httpClient.get<GetCompanyLeaderboardResponse>(
      `/gamification/leaderboard/company/${companyId}`,
      {
        isAuthorized: true,
        params: {
          month,
        },
      }
    );

    return res.items;
  };

  public readonly getChallenge = async ({
    companyId,
    datetime,
  }: GetChallengeRequest) => {
    const res = await this.httpClient.get<GetChallengeResponse>(
      `/gamification/company/${companyId}/challenge`,
      {
        isAuthorized: true,
        params: {
          datetime,
        },
      }
    );

    return res.item;
  };

  public readonly getHistoryChallenges = async ({
    companyId,
  }: GetHistoryChallengesRequest) => {
    const res = await this.httpClient.get<GetChallengesResponse>(
      `/gamification/company/${companyId}/history`,
      { isAuthorized: true }
    );

    return res.items;
  };

  public readonly getAchievements = async ({
    companyId,
  }: GetAchievementsRequest) => {
    const res = await this.httpClient.get<GetAchievementsResponse>(
      `/gamification/company/${companyId}/achievements`,
      { isAuthorized: true }
    );
    return res.items;
  };
}
