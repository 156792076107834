import * as React from 'react';
import Card from './Card';
import ButtonToggle, { ButtonToggleElement } from './ButtonToggle';

export type TabElement<T> = {
  id: T;
  label: string;
  component: React.ReactNode;
};

type TabViewProps<T> = {
  elements: TabElement<T>[];
  className?: string;
  selectedElementId: T;
  onChange?: (key: T) => void;
};

const TabView = <T,>({
  elements,
  selectedElementId,
  className = '',
  onChange,
}: TabViewProps<T>) => {
  const [selectedTab, setSelectedTab] = React.useState<T>(selectedElementId);

  React.useEffect(() => {
    onChange?.(selectedTab);
  }, [onChange, selectedTab]);

  const buttonToggleElements: ButtonToggleElement<T>[] = React.useMemo(() => {
    return elements.map((e) => ({
      key: e.id,
      label: e.label,
    }));
  }, [elements]);

  const selectedTabComponent = React.useMemo(() => {
    return elements.find((e) => e.id === selectedTab)?.component;
  }, [elements, selectedTab]);

  React.useEffect(() => {
    setSelectedTab(selectedElementId);
  }, [selectedElementId]);

  const onButtonToggleChange = React.useCallback((key: T) => {
    setSelectedTab(key);
  }, []);

  return (
    <Card className={className}>
      <ButtonToggle
        elements={buttonToggleElements}
        activeElementKey={selectedTab}
        onChange={onButtonToggleChange}
      />
      <div className="mt-4">{selectedTabComponent}</div>
    </Card>
  );
};

export default TabView;
