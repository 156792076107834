import React, { ReactNode } from 'react';
import ParagraphPrimary from './ParagraphPrimary';

type PopoverActionProps = {
  icon?: ReactNode;
  text: string;
  onClick?: () => void;
};

const PopoverAction: React.FC<PopoverActionProps> = ({
  icon,
  text,
  onClick,
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="p-2 hover:bg-card min-w-fit flex cursor-pointer items-center"
    >
      {icon && <div className="mr-2">{icon}</div>}
      <ParagraphPrimary>{text}</ParagraphPrimary>
    </div>
  );
};

export default PopoverAction;
