import { FeedbackVote } from '../api/feedback/feedback.models';

export function getClassColorFromValue(value: FeedbackVote) {
  switch (value) {
    case 1:
      return 'danger';
    case 2:
      return 'sad';
    case 3:
      return 'happy';
    case 4:
      return 'primary';
    default:
      return 'primary';
  }
}
