import React from 'react';
import { DashboardLayout, TabElement, TabView } from '../../components';
import { useLanguage } from '../../locale';

import useFeatureFlags from '../../hooks/useFeatureFlags';

import LeaderboardTable from './components/LeaderboardTable';
import SectionLocked from './components/SectionLocked';
import ChallengeSection from './components/ChallengeSection';

type LeaderboardPageProps = {
  initialTab?: string;
};

enum LeaderBoardTab {
  Leaderboard = 'leaderboard',
  Challenge = 'challenge',
}

const LeaderboardPage: React.FC<LeaderboardPageProps> = ({ initialTab }) => {
  const [t] = useLanguage();
  const [FeatureFlags] = useFeatureFlags();

  const tabElements: TabElement<LeaderBoardTab>[] = React.useMemo(() => {
    return [
      {
        id: LeaderBoardTab.Leaderboard,
        label: t('dashboard.leaderboard.leaderboardTab'),
        component: FeatureFlags.GamificationLeaderboard ? (
          <LeaderboardTable />
        ) : (
          <SectionLocked
            title={t('shared.functionLocked.title')}
            description={t('shared.functionLocked.description')}
          />
        ),
      },
      {
        id: LeaderBoardTab.Challenge,
        label: t('dashboard.leaderboard.challengeTab'),
        component: FeatureFlags.GamificationChallenges ? (
          <ChallengeSection />
        ) : (
          <SectionLocked
            title={t('shared.functionLocked.title')}
            description={t('shared.functionLocked.description')}
          />
        ),
      },
    ];
  }, [t, FeatureFlags]);

  return (
    <DashboardLayout
      title={t('dashboard.leaderboard.title')}
      description={t('dashboard.leaderboard.description')}
    >
      <TabView
        selectedElementId={
          (initialTab as LeaderBoardTab) ?? LeaderBoardTab.Leaderboard
        }
        className="my-6 flex-1 flex flex-col items-stretch min-h-[300px] "
        elements={tabElements}
      />
    </DashboardLayout>
  );
};

export default LeaderboardPage;
