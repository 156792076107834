import {
  SignInRequest,
  SignUpRequest,
  VerifyCodeRequest,
} from '../../api/authorization/authorization.models';
import { useApi } from '../../context/ApiContext/useApi';
import { useAppMutation } from './utils';

export const useSignInMutation = () => {
  const { authApi } = useApi();
  const mutation = useAppMutation((params: SignInRequest) =>
    authApi.signIn(params)
  );
  return mutation;
};

export const useSignUpMutation = () => {
  const { authApi } = useApi();
  const mutation = useAppMutation((params: SignUpRequest) =>
    authApi.signUp(params)
  );
  return mutation;
};

export const useVerifyCodeMutation = () => {
  const { authApi } = useApi();
  const mutation = useAppMutation((params: VerifyCodeRequest) =>
    authApi.verifyCode(params)
  );
  return mutation;
};
