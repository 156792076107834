import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loading } from './components';
import { ApiProvider } from './context/ApiContext/ApiContext';
import { ErrorHandlerProvider } from './context/ErrorHandlerContext/ErrorHandlerContext';
import { StorageProviderProvider } from './context/StorageProviderContext/StorageProviderContext';
import { LocalizationProvider } from './locale';
import GeneralRoutes from './routes/general.routes';
import { LoaderProvider } from './context/LoaderContext/LoaderContext';
import { UserRoleProvider } from './context/RoleContext/UserRoleContext';
import { ToastProvider } from './context/ToastContext/ToastContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      retry: 0,
    },
  },
});

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <LoaderProvider>
          <StorageProviderProvider>
            <ErrorHandlerProvider>
              <ApiProvider>
                <LocalizationProvider>
                  <UserRoleProvider>
                    <HelmetProvider>
                      <Suspense fallback={<Loading />}>
                        <BrowserRouter>
                          <GeneralRoutes />
                        </BrowserRouter>
                      </Suspense>
                    </HelmetProvider>
                  </UserRoleProvider>
                </LocalizationProvider>
              </ApiProvider>
            </ErrorHandlerProvider>
          </StorageProviderProvider>
        </LoaderProvider>
      </ToastProvider>
    </QueryClientProvider>
  );
};

export default App;
