import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignInMutation, useVerifyCodeMutation } from '../../../hooks';
import { setIsAuthorized } from '../../../redux/general/general.slice';
import { AppRoutes } from '../../../routes/routes';

export type AuthCodePageRouteState = {
  email: string | undefined;
  session: string | undefined;
};

export const useAuthCodeController = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as AuthCodePageRouteState;

  const [currentSession, setCurrentSession] = useState(locationState?.session);
  const [code, setCode] = useState('');

  const { mutateAsync: verifyCodeApiCall } = useVerifyCodeMutation();
  const { mutateAsync: resendEmailApiCall } = useSignInMutation();

  useEffect(() => {
    if (!locationState?.email || !locationState?.session) {
      navigate(AppRoutes.Autenthication);
    }
  }, [locationState?.email, locationState?.session, navigate]);

  const verifyCode = useCallback(async () => {
    const res = await verifyCodeApiCall({
      email: locationState.email!,
      otp: code,
      session: currentSession!,
    });

    if (res.AuthenticationResult.AccessToken) {
      dispatch(setIsAuthorized(true));
    }
  }, [code, currentSession, dispatch, locationState?.email, verifyCodeApiCall]);

  const resendEmail = useCallback(async () => {
    const res = await resendEmailApiCall({ email: locationState.email! });
    if (res) {
      setCurrentSession(res.Session);
    }
  }, [locationState?.email, resendEmailApiCall]);

  return {
    code,
    setCode,
    resendEmail,
    verifyCode,
    email: locationState?.email,
  };
};
