import * as React from 'react';
import { createNotImplementedMethod } from '../../api/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FC } from '../../types/general.types';

export enum ToastType {
  Error = 'error',
  Success = 'success',
}

export type OpenToastProps = {
  type?: ToastType;
  message: string;
};

export type ToastContextProps = {
  openToast: (props: OpenToastProps) => void;
};

export const ToastContext = React.createContext<ToastContextProps>({
  openToast: createNotImplementedMethod(),
});

export const ToastProvider: FC = ({ children }) => {
  const openToast = React.useCallback(
    ({ type = ToastType.Error, message }: OpenToastProps) => {
      switch (type) {
        case ToastType.Success:
          toast.success(message);
          break;
        case ToastType.Error:
          toast.error(message);
          break;
      }
    },
    []
  );

  return (
    <ToastContext.Provider value={{ openToast }}>
      {children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ToastContext.Provider>
  );
};
