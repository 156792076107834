import { useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { LeaderboardElement } from '../../../api/gamification/gamification.models';
import ProfileImagePlaceholder from '../../../components/ProfileImagePlaceholder';
import { useLanguage } from '../../../locale';

export const useLeaderboardTableColumn = () => {
  const [t] = useLanguage();

  const columns: TableColumn<LeaderboardElement>[] = useMemo(() => {
    return [
      {
        name: t('dashboard.leaderboard.positionColumn'),
        selector: (_, index) => `${index ? index + 1 : index}`,
        format: (_, index) => (
          <div title={`${index ? index + 1 : index}`}>{`${
            index ? index + 1 : 1
          }`}</div>
        ),
        width: '82px',
      },
      {
        selector: (row) => row.user.username,
        format: (row) => (
          <ProfileImagePlaceholder username={row.user.username} />
        ),
        width: '82px',
      },
      {
        name: t('dashboard.leaderboard.usernameColumn'),
        selector: (row) => row.user.username,
        format: (row) => (
          <div title={row.user.username}>{row.user.username}</div>
        ),
      },
      {
        name: t('dashboard.leaderboard.pointsColumn'),
        selector: (row) => row.userId,
        format: (row) => <div title={`${row.points}`}>{row.points}</div>,
      },
      // {
      //   selector: (row) => row.userId,
      //   right: true,
      //   format: (row) => (
      //     <ActionColumn isAdmin={row.isAdmin} userId={row.userId} />
      //   ),
      // },
    ];
  }, [t]);

  return { columns };
};
