import { PayloadAction } from '@reduxjs/toolkit';
import { GeneralState } from './general.slice';

export const addFullPageLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.fullPageLoaders.push(action.payload);
};

export const removeFullPageLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.fullPageLoaders = state.fullPageLoaders.filter(
    (l) => l !== action.payload
  );
};

export const addLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.loaders.push(action.payload);
};

export const removeLoaderHandler = (
  state: GeneralState,
  action: PayloadAction<string>
) => {
  state.loaders = state.loaders.filter((l) => l !== action.payload);
};

export const setIsAuthorizedHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isAuthorized = action.payload;
};

export const setIsInitializedHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isInitialized = action.payload;
};

export const setIsInErrorHandler = (
  state: GeneralState,
  action: PayloadAction<boolean>
) => {
  state.isInError = action.payload;
};

export const resetGeneralToDefaultHandler = (state: GeneralState) => {
  state.fullPageLoaders = [];
  state.isInError = false;
  state.loaders = [];
  state.isAuthorized = false;
};
