import { createNotImplementedMethod } from '../utils';
import {
  AddUserToCompanyRequest,
  AddUserToCompanyResponse,
  Company,
  CreateCompanyRequest,
  GetCompanyRequest,
  GetCompanyUsersRequest,
  GetCompanyUsersResponse,
  GetMemberByIdRequest,
  GetMemberByIdResponse,
  GetMyCompaniesRequest,
  PromoteUserRequest,
  RemoveUserFromCompanyRequest,
  SearchCompanyUsersRequest,
  SearchCompanyUsersResponse,
  UpdateCompanyRequest,
} from './companies.models';

export interface ICompanyApi {
  readonly getMyCompanies: (req: GetMyCompaniesRequest) => Promise<Company[]>;
  readonly getCompany: (req: GetCompanyRequest) => Promise<Company>;
  readonly editCompany: (req: UpdateCompanyRequest) => Promise<void>;
  readonly createCompany: (req: CreateCompanyRequest) => Promise<Company>;
  readonly getMemberById: (
    req: GetMemberByIdRequest
  ) => Promise<GetMemberByIdResponse>;
  readonly getCompanyUsers: (
    req: GetCompanyUsersRequest
  ) => Promise<GetCompanyUsersResponse>;
  readonly searchUsers: (
    req: SearchCompanyUsersRequest
  ) => Promise<SearchCompanyUsersResponse>;
  readonly removeUserFromCompany: (
    req: RemoveUserFromCompanyRequest
  ) => Promise<void>;
  readonly promoteUserToUser: (req: PromoteUserRequest) => Promise<void>;
  readonly promoteUserToAdmin: (req: PromoteUserRequest) => Promise<void>;
  readonly addUserToCompany: (
    req: AddUserToCompanyRequest
  ) => Promise<AddUserToCompanyResponse>;
}

export class NotImplementedCompanyApi implements ICompanyApi {
  public readonly getMyCompanies = createNotImplementedMethod();
  public readonly getCompany = createNotImplementedMethod();
  public readonly editCompany = createNotImplementedMethod();
  public readonly createCompany = createNotImplementedMethod();
  public readonly getCompanyUsers = createNotImplementedMethod();
  public readonly getMemberById = createNotImplementedMethod();
  public readonly searchUsers = createNotImplementedMethod();
  public readonly removeUserFromCompany = createNotImplementedMethod();
  public readonly promoteUserToUser = createNotImplementedMethod();
  public readonly promoteUserToAdmin = createNotImplementedMethod();
  public readonly addUserToCompany = createNotImplementedMethod();
}
