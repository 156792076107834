import * as React from 'react';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}

type ButtonProps = {
  children: React.ReactNode;
  type?: ButtonType;
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  type = ButtonType.Primary,
  isDisabled = false,
  onClick,
  className,
}) => {
  const switchableStyle = React.useMemo(() => {
    switch (type) {
      case ButtonType.Primary:
        return 'bg-primary text-white';
      case ButtonType.Secondary:
        return 'bg-card text-fake-black border border-solid border-fake-black';
      case ButtonType.Danger:
        return 'bg-danger text-white';
    }
  }, [type]);

  return (
    <button
      className={`${switchableStyle} rounded-md border-0 flex items-center justify-center ${
        !isDisabled ? 'hover:shadow-lg' : ''
      } ${!isDisabled ? 'hover:-translate-y-0.5' : ''} ${
        isDisabled ? 'opacity-30' : ''
      } transition duration-200 ease-in-out font-medium py-2 px-3 ${className}`}
      onClick={!isDisabled ? onClick : undefined}
    >
      {children}
    </button>
  );
};

export default Button;
