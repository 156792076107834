import * as React from 'react';
import DeleteCompanyActionModal from './DeleteCompanyActionModal';
import { ModalRef } from '../Modal';
import { deleteButtonImage } from '../../assets/images';
import { EyeIcon } from '@heroicons/react/24/solid';

type CompanyActionColumnProps = {
  actionId: string;
  companyId: string;
  onView: () => void;
};

const CompanyActionColumn: React.FC<CompanyActionColumnProps> = ({
  actionId,
  companyId,
  onView,
}) => {
  const deleteModalRef = React.useRef<ModalRef>(null);

  const openDeleteModal = React.useCallback(() => {
    deleteModalRef.current?.openModal();
  }, []);

  return (
    <>
      <div className="flex">
        <button
          onClick={onView}
          className="border-0 bg-light-green w-8 h-8 p-1.5 flex justify-center items-center rounded-full"
        >
          <EyeIcon className="text-primary" />
        </button>
        <button
          onClick={openDeleteModal}
          className="border-0 bg-none w-8 h-8 flex justify-center items-center ml-4"
        >
          <img src={deleteButtonImage} alt="Delete action" />
        </button>
      </div>
      <DeleteCompanyActionModal
        ref={deleteModalRef}
        actionId={actionId}
        companyId={companyId}
      />
    </>
  );
};

export default CompanyActionColumn;
