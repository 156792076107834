import { createSelector } from 'reselect';
import { RootState } from '../store';

const getLoader = (state: RootState, loaderKey: string) =>
  state.general.loaders.find((l) => l === loaderKey);

export const makeIsLoadingSelector = () =>
  createSelector([getLoader], (loader) => !!loader);

export const isSomethingLoading = (state: RootState) =>
  state.general.fullPageLoaders.length > 0;

export const isAuthorizedSelector = (state: RootState) =>
  state.general.isAuthorized;

export const isInitializedSelector = (state: RootState) =>
  state.general.isInitialized;
