import { CompanyActionLevel } from '../api/companyAction/companyAction.models';
import { CallbackTranslation } from '../locale/localizationContext';

export function getCompanyActionLevelTranslation(
  t: CallbackTranslation,
  actionLevel: CompanyActionLevel
) {
  switch (actionLevel) {
    case CompanyActionLevel.Company:
      return t('shared.companyActionLevel.company');
    case CompanyActionLevel.Team:
      return t('shared.companyActionLevel.team');
    case CompanyActionLevel.User:
      return t('shared.companyActionLevel.user');
  }
}
