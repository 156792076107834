import React, { ReactNode } from 'react';
import { logoImage } from '../assets/images';
import Card from './Card';

export type AuthNavbarProps = {
  rightButton?: ReactNode;
  leftButton?: ReactNode;
};

const AuthNavbar: React.FC<AuthNavbarProps> = ({ rightButton, leftButton }) => {
  return (
    <Card className="mb-6 flex justify-between items-center">
      {!leftButton && <div className="h-10 w-10" />}
      {leftButton && <div className="h-10 w-10">{leftButton}</div>}
      <img className="max-h-12" src={logoImage} alt="Qomprendo Logo" />
      {rightButton && <div className="h-10 w-10">{rightButton}</div>}
      {!rightButton && <div className="h-10 w-10" />}
    </Card>
  );
};

export default AuthNavbar;
