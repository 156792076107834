import { Challenge } from '../../../api/gamification/gamification.models';
import { Subtitle } from '../../../components';
import { useLanguage } from '../../../locale';

import PastChallengeCard from './PastChallengeCard';

interface PastChallengesSectionProps {
  challenges: Challenge[];
  historyMetrics: Record<string, { label: string; total: number }>;
}

const PastChallengesSection = ({
  challenges,
  historyMetrics,
}: PastChallengesSectionProps) => {
  const [t] = useLanguage();

  return (
    <>
      {Object.values(historyMetrics).length > 0 && (
        <>
          <Subtitle className="mb-2">
            {t('dashboard.leaderboard.gamification.historySection.allResults')}
          </Subtitle>
          <div className="flex-row flex flex-wrap justify-around">
            {Object.values(historyMetrics).map(({ label, total }) => (
              <div key={label} className="mb-6 flex-col flex items-center">
                <p className="font-medium mb-1 text-xs">{label}</p>
                <p className="bg-primary overflow-hidden py-1 rounded-xl text-center text-white px-6">
                  {total}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
      <Subtitle className="mb-2">
        {t('dashboard.leaderboard.gamification.history')}
      </Subtitle>
      <div className="flex flex-col gap-2 h-[50%]">
        {challenges.map((c) => (
          <PastChallengeCard challenge={c} />
        ))}
      </div>
    </>
  );
};

export default PastChallengesSection;
