import dayjs from 'dayjs';
import {
  useGetAchievements,
  useGetChallenge,
  useGetChallengesHistory,
} from '../../../hooks/api/gamification.api';
import { useParams } from 'react-router-dom';
import {
  Labels,
  Metric,
  Metrics,
} from '../../../api/gamification/gamification.models';
import { useMemo } from 'react';
import { useLanguage } from '../../../locale';

const challengeDate = dayjs()
  .subtract(1, 'day')
  .set('seconds', 0)
  .set('minutes', 0)
  .set('hours', 13)
  .format('YYYY-MM-DDTHH:mm:ssZ');

const useCurrentChallengeController = () => {
  const { companyId } = useParams();
  const [, , currentLocale] = useLanguage();

  const { data: challenge, isLoading } = useGetChallenge({
    companyId,
    datetime: challengeDate,
  });

  const { data: challenges } = useGetChallengesHistory({
    companyId,
  });

  const historyExist = useMemo(
    () => !!challenges && challenges.length > 0,
    [challenges]
  );
  const challengeExist = useMemo(() => !!challenge, [challenge]);

  const leftDays = useMemo(() => {
    if (!challenge) return null;
    const daysLeftNotRounded =
      dayjs(challenge?.endsAt).diff(dayjs()) / 1000 / 60 / 60 / 24 + 1;
    if (daysLeftNotRounded < 1) return null;
    return Math.round(daysLeftNotRounded + Number.EPSILON);
  }, [challenge]);

  const currentChallengePoints = useMemo(() => {
    if (!challenge) return null;
    return challenge.currentAmount;
  }, [challenge]);

  const challengePrizes = useMemo(() => {
    if (!challenge) return [];
    return [...challenge.targets].reverse();
  }, [challenge]);

  const completedPercentage = useMemo(() => {
    if (!currentChallengePoints || !challenge) return 0;
    const percentage = (currentChallengePoints / challenge.targetAmount) * 100;

    return Math.min(percentage, 100);
  }, [challenge, currentChallengePoints]);

  const nextPrizePoints = useMemo(() => {
    if (!challenge || !currentChallengePoints === null) return null;
    let nextPrize = challenge.targets.find(
      (t) => completedPercentage < t.minPercent
    );
    if (!nextPrize) nextPrize = challenge.targets.at(-1);

    return challenge.targetAmount * (nextPrize!.minPercent / 100);
  }, [challenge, completedPercentage, currentChallengePoints]);

  const completedPrizes = useMemo(() => {
    if (!challenge || !currentChallengePoints) return null;
    const filteredPrizes = challenge.targets.filter(
      (t) => completedPercentage >= t.minPercent
    );

    return filteredPrizes;
  }, [challenge, completedPercentage, currentChallengePoints]);

  const currentMetrics = useMemo(() => {
    return completedPrizes?.reduce((calculatedMetrics, completedPrize) => {
      const weightedMetrics = Object.keys(completedPrize.prize.metrics).reduce(
        (pv, cv) => {
          const metric = completedPrize.prize.metrics[cv];
          return {
            ...pv,
            [cv]: {
              amount: metric.amount * completedPrize.prizeQta,
              unit: metric.unit,
              labels: metric.labels,
            },
          };
        },
        {} as Metrics
      );

      const finalMetrics = Object.keys(weightedMetrics).reduce((pv, cv) => {
        const calculatedMetric: Metric | undefined = calculatedMetrics[cv];

        if (!calculatedMetric) {
          return {
            ...pv,
            [cv]: { ...weightedMetrics[cv] },
          };
        } else {
          return {
            ...pv,
            [cv]: {
              ...calculatedMetric,
              amount: calculatedMetric.amount + weightedMetrics[cv].amount,
            },
          };
        }
      }, {} as Metrics);

      return finalMetrics;
    }, {} as Metrics);
  }, [completedPrizes]);

  const { data: achievements = [] } = useGetAchievements({
    companyId,
  });

  const historyMetrics = useMemo(() => {
    return achievements
      .flatMap(({ metrics }) => Object.entries(metrics))
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: acc[key]
            ? { ...acc[key], total: acc[key].total + value.amount }
            : {
                label: value.labels[currentLocale as keyof Labels],
                total: value.amount,
              },
        }),
        {} as Record<string, { label: string; total: number }>
      );
  }, [achievements, currentLocale]);

  return {
    completedPercentage,
    currentMetrics,
    currentChallengePoints,
    challengePrizes,
    nextPrizePoints,
    completedPrizes,
    challengeExist,
    leftDays,
    historyExist,
    challenges,
    isLoading,
    historyMetrics,
  };
};

export default useCurrentChallengeController;
