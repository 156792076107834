import { IStorageProvider, StorageKeys } from './IStorageProvider';

export class MobileStorageProvider implements IStorageProvider {
  getItem: (key: StorageKeys) => Promise<string | null> = (key) => {
    return Promise.resolve(localStorage.getItem(key));
  };
  setItem: (key: StorageKeys, value: string) => Promise<void> = (key, val) => {
    return Promise.resolve(localStorage.setItem(key, val));
  };
  removeItem: (key: StorageKeys) => Promise<void> = (key) => {
    return Promise.resolve(localStorage.removeItem(key));
  };
}
