import ReactModal from 'react-modal';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { timesImage } from '../assets/images';

const AnyReactModal: any = ReactModal;

type ModalProps = {
  children?: ReactNode | null;
  showClose?: boolean;
  overlayStyle?: ReactModal.Styles['overlay'];
  contentStyle?: ReactModal.Styles['content'];
  onOpen?: () => void;
  onClose?: () => void;
};

export type ModalRef = {
  openModal: () => void;
  closeModal: () => void;
};

export const defaultOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 9999,
} as const;

export const defaultContentStyle = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  width: '95%',
  maxWidth: '456px',
  borderRadius: '24px',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
} as const;

// TODO: Not consider when in test
ReactModal.setAppElement('#root');

const Modal: React.ForwardRefExoticComponent<
  ModalProps & React.RefAttributes<ModalRef>
> = forwardRef(
  (
    {
      children,
      onOpen,
      onClose,
      showClose = false,
      overlayStyle = defaultOverlayStyle,
      contentStyle = defaultContentStyle,
    },
    forwardedRef
  ) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const modalStyle: ReactModal.Styles = useMemo(() => {
      return {
        content: contentStyle,
        overlay: overlayStyle,
      };
    }, [contentStyle, overlayStyle]);

    const openModal = useCallback(() => {
      onOpen?.();
      setIsModalVisible(true);
    }, [onOpen]);

    const closeModal = useCallback(() => {
      onClose?.();
      setIsModalVisible(false);
    }, [onClose]);

    useImperativeHandle(forwardedRef, () => ({
      openModal,
      closeModal,
    }));

    return (
      <AnyReactModal
        style={modalStyle}
        isOpen={isModalVisible}
        closeTimeoutMS={250}
      >
        <div className="flex flex-col h-full">
          {showClose && (
            <div className="flex mb-4 justify-end">
              <button
                onClick={closeModal}
                className="w-5 h-5 border-0 bg-transparent"
              >
                <img src={timesImage} alt="close image" />
              </button>
            </div>
          )}
          {children}
        </div>
      </AnyReactModal>
    );
  }
);

export default Modal;
