import * as React from 'react';
import Modal, { ModalRef } from '../Modal';
import { useLanguage } from '../../locale';
import Button, { ButtonType } from '../Button';
import Title from '../Title';
import {
  CompanyActionLevel,
  CompanyActionType,
} from '../../api/companyAction/companyAction.models';
import useAddCompanyActionValidation, {
  AddCompanyActionForm,
} from '../../hooks/validation/useAddCompanyActionValidation';
import TextInput from '../TextInput';
import TextArea from '../TextArea';
import ParagraphSecondary from '../ParagraphSecondary';
import { useCreateCompanyAction } from '../../hooks/api/companyAction/useCreateCompanyAction';
import ParagraphPrimary from '../ParagraphPrimary';
import RadioButton from '../RadioButton';
import { useUpdateCompanyAction } from '../../hooks/api/companyAction/useUpdateCompanyAction';
import dayjs from 'dayjs';

type AddCompanyActionModalProps = {
  companyId: string;
  actionLevel: CompanyActionLevel;
  entityId: string;
  prevDataAction?: AddCompanyActionForm & {
    actionId: string;
    actionType: CompanyActionType;
  };
  onClose?: () => void;
};

const AddCompanyActionModal: React.ForwardRefExoticComponent<
  AddCompanyActionModalProps & React.RefAttributes<ModalRef>
> = React.forwardRef(
  (
    { actionLevel, prevDataAction, companyId, entityId, onClose },
    forwardedRef
  ) => {
    const [t] = useLanguage();
    const modalRef = React.useRef<ModalRef>(null);
    const [actionType, setActionType] = React.useState(
      CompanyActionType.Action
    );
    const [isViewMode, setIsViewMode] = React.useState(false);

    const isEdit = !!prevDataAction;

    const titleLevelTranslation = React.useMemo(() => {
      switch (actionLevel) {
        case CompanyActionLevel.Company:
          return t('shared.createCompanyActionModal.company');
        case CompanyActionLevel.Team:
          return t('shared.createCompanyActionModal.team');
        case CompanyActionLevel.User:
          return t('shared.createCompanyActionModal.user');
      }
    }, [actionLevel, t]);

    const descriptionLevelTranslation = React.useMemo(() => {
      switch (actionLevel) {
        case CompanyActionLevel.Company:
          return t('shared.createCompanyActionModal.companyLevel');
        case CompanyActionLevel.Team:
          return t('shared.createCompanyActionModal.teamLevel');
        case CompanyActionLevel.User:
          return t('shared.createCompanyActionModal.userLevel');
      }
    }, [actionLevel, t]);

    const {
      descriptionValue,
      errors,
      handleSubmit,
      implementationDateValue,
      onDescriptionChange,
      onImplementationDateChange,
      onTitleChange,
      titleValue,
      reset,
    } = useAddCompanyActionValidation(prevDataAction);

    const { mutateAsync: createCompanyAction } = useCreateCompanyAction();
    const { mutateAsync: editCompanyAction } = useUpdateCompanyAction();

    React.useEffect(() => {
      if (prevDataAction) {
        setIsViewMode(true);
        setActionType(prevDataAction?.actionType);
      } else {
        setIsViewMode(false);
        setActionType(CompanyActionType.Action);
      }
    }, [prevDataAction]);

    const openModal = React.useCallback(() => {
      modalRef.current?.openModal();
    }, []);

    const closeModal = React.useCallback(() => {
      setActionType(CompanyActionType.Action);
      reset();
      onClose?.();
      modalRef.current?.closeModal();
    }, [onClose, reset]);

    React.useImperativeHandle(forwardedRef, () => ({
      openModal,
      closeModal,
    }));

    const onSave = React.useMemo(() => {
      return handleSubmit(async (values) => {
        try {
          if (isEdit && !isViewMode) {
            await editCompanyAction({
              companyId,
              actionId: prevDataAction.actionId,
              heading: values.title,
              body: values.description,
              implementationDate: dayjs(values.implementationDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            });
          } else if (!isViewMode) {
            await createCompanyAction({
              companyId,
              type: actionType,
              entityId,
              actionLevel,
              heading: values.title,
              body: values.description,
              implementationDate: dayjs(values.implementationDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            });
          }
          closeModal();
        } catch {
          // No need, already handled at root
        }
      });
    }, [
      actionLevel,
      actionType,
      closeModal,
      companyId,
      createCompanyAction,
      editCompanyAction,
      entityId,
      handleSubmit,
      isEdit,
      isViewMode,
      prevDataAction?.actionId,
    ]);

    const enableEdit = React.useCallback(() => {
      setIsViewMode(false);
    }, []);

    const onSelectActionType = React.useCallback((val: CompanyActionType) => {
      setActionType(val);
    }, []);

    const titlePart = React.useMemo(() => {
      if (isViewMode) {
        return t('shared.createCompanyActionModal.view');
      } else if (isEdit) {
        return t('shared.createCompanyActionModal.edit');
      } else {
        return t('shared.createCompanyActionModal.add');
      }
    }, [isEdit, isViewMode, t]);

    return (
      <Modal ref={modalRef}>
        <Title>
          {t('shared.createCompanyActionModal.title', {
            level: titleLevelTranslation,
            action: titlePart,
          })}
        </Title>
        {!isViewMode && (
          <ParagraphSecondary className="mt-2">
            {t('shared.createCompanyActionModal.actionDescription', {
              actionLevel: descriptionLevelTranslation,
            })}
          </ParagraphSecondary>
        )}
        <div className="mt-4 flex flex-col">
          <TextInput
            isDisabled={isViewMode}
            onValueChange={onTitleChange}
            value={titleValue}
            error={errors.title?.message}
            placeholder={t(
              'shared.createCompanyActionModal.headingPlaceholder'
            )}
          />
          {actionLevel === CompanyActionLevel.User && (
            <div className="mt-4">
              <ParagraphPrimary className="font-medium">
                {t('shared.createCompanyActionModal.userTypeAction')}:
              </ParagraphPrimary>
              <div className="flex flex-row items-center flex-wrap mt-2">
                <RadioButton
                  isSelected={actionType === CompanyActionType.Action}
                  label={t('shared.createCompanyActionModal.actionType')}
                  isDisabled={isViewMode}
                  onRadioClick={onSelectActionType}
                  value={CompanyActionType.Action}
                  className="mr-4"
                />
                <RadioButton
                  isSelected={actionType === CompanyActionType.OneToOne}
                  label={t('shared.createCompanyActionModal.oneOnOneType')}
                  isDisabled={isViewMode}
                  onRadioClick={onSelectActionType}
                  value={CompanyActionType.OneToOne}
                />
              </div>
            </div>
          )}
          <TextArea
            className="mt-4"
            onValueChange={onDescriptionChange}
            value={descriptionValue}
            error={errors.description?.message}
            isDisabled={isViewMode}
            placeholder={t(
              'shared.createCompanyActionModal.descriptionPlaceholder'
            )}
          />
          <TextInput
            className="mt-4"
            type="date"
            placeholder={t(
              'shared.createCompanyActionModal.implementationDatePlaceholder'
            )}
            isDisabled={isViewMode}
            onValueChange={onImplementationDateChange}
            value={implementationDateValue}
          />
        </div>
        {!isViewMode && (
          <div className="mt-8 flex">
            <Button
              type={ButtonType.Secondary}
              onClick={closeModal}
              className="flex-1"
            >
              {t('shared.createCompanyActionModal.cancel')}
            </Button>
            <Button onClick={onSave} className="flex-1 ml-4">
              {t('shared.createCompanyActionModal.save')}
            </Button>
          </div>
        )}
        {isViewMode && (
          <div className="mt-8 flex">
            <Button
              type={ButtonType.Secondary}
              onClick={closeModal}
              className="flex-1"
            >
              {t('shared.createCompanyActionModal.cancel')}
            </Button>
            <Button onClick={enableEdit} className="flex-1 ml-4">
              {t('shared.createCompanyActionModal.startEdit')}
            </Button>
          </div>
        )}
      </Modal>
    );
  }
);

export default AddCompanyActionModal;
