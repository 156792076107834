import React from 'react';
import { Link } from 'react-router-dom';
import { AnimatedPage } from '.';
import { editButtonImage } from '../assets/images';
import { useGetMyUser } from '../hooks';
import { AppRoutes } from '../routes/routes';
import ParagraphSecondary from './ParagraphSecondary';
import ProfileImagePlaceholder from './ProfileImagePlaceholder';
import Title from './Title';
import { FC } from '../types/general.types';

type DashboardLayoutProps = {
  title?: string;
  description?: string;
  viewportHeight?: boolean;
  onEdit?: () => void;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  title,
  description,
  viewportHeight,
  onEdit,
}) => {
  const { data: user } = useGetMyUser();

  return (
    <AnimatedPage>
      <div
        className={`flex flex-col items-stretch mr-8 min-h-screen ${
          viewportHeight ? 'max-h-screen overflow-hidden' : ''
        }`}
      >
        <div className="flex justify-between py-4">
          <div className="flex-1" />
          <Link className="flex items-center" to={AppRoutes.Profile}>
            <p className="mr-2">{user?.username}</p>
            {user && <ProfileImagePlaceholder username={user?.username} />}
          </Link>
        </div>
        {title && (
          <div className="mt-4">
            <div className="flex">
              <Title>{title}</Title>
              {onEdit && (
                <button onClick={onEdit} className="border-0 p-0 ml-4">
                  <img src={editButtonImage} alt="Edit" />
                </button>
              )}
            </div>
            {description && (
              <ParagraphSecondary className="max-w-[400px] mt-2">
                {description}
              </ParagraphSecondary>
            )}
          </div>
        )}
        {children}
      </div>
    </AnimatedPage>
  );
};

export default DashboardLayout;
