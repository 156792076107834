import { createNotImplementedMethod } from '../utils';
import {
  SignInRequest,
  TokensResponse,
  SignUpRequest,
  VerifyCodeRequest,
  SignInResponse,
} from './authorization.models';

export interface IAuthorizationApi {
  readonly signIn: (loginBody: SignInRequest) => Promise<SignInResponse>;
  readonly signUp: (signUpBody: SignUpRequest) => Promise<{}>;
  readonly verifyCode: (
    verifyCodeBody: VerifyCodeRequest
  ) => Promise<TokensResponse>;
  readonly refresh: (refreshToken: string) => Promise<TokensResponse>;
  readonly revokeToken: () => Promise<void>;
}

export class NotImplementedAuthorizationApi implements IAuthorizationApi {
  readonly signIn = createNotImplementedMethod();
  readonly signUp = createNotImplementedMethod();
  readonly verifyCode = createNotImplementedMethod();
  readonly refresh = createNotImplementedMethod();
  readonly revokeToken = createNotImplementedMethod();
}
