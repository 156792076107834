import { Subtitle } from '../../../components';
import { useLanguage } from '../../../locale';
import { getCorrectMedalImage } from '../../../utils/getCorrectMedalImage';
import useCurrentChallengeController from '../controllers/useCurrentChallengeController';

const CurrentChallengeComponent = () => {
  const [t] = useLanguage();
  const {
    leftDays,
    isLoading,
    completedPercentage,
    challengePrizes,
    currentChallengePoints,
    nextPrizePoints,
    completedPrizes,
    currentMetrics,
  } = useCurrentChallengeController();

  if (isLoading)
    return (
      <>
        <p>{t('dashboard.leaderboard.gamification.loading')}</p>
      </>
    );

  return (
    <div className="flex flex-col gap-2 p-2">
      <p>
        {t('dashboard.leaderboard.gamification.daysLeft')}{' '}
        <span className="font-bold">{leftDays}</span>
      </p>
      <div className=" mt-5 bg-card relative w-full h-3 rounded-full overflow-visible  border border-primary">
        <div
          className={`bg-primary absolute h-full rounded-full `}
          style={{ width: completedPercentage + '%' }}
        />
        {challengePrizes.map((p) => {
          const medalImage = getCorrectMedalImage(p.icon);
          return (
            <div
              key={`indicator-${p.minPercent}`}
              className="absolute h-6 w-6"
              style={{
                left: `${p.minPercent - 2}%`,
                top: '-80%',
              }}
            >
              <img className="h-6 w-6" src={medalImage} />
            </div>
          );
        })}
      </div>
      {currentChallengePoints !== null && nextPrizePoints !== null ? (
        currentChallengePoints >= nextPrizePoints ? (
          <p>{t('dashboard.leaderboard.gamification.unlockAllPrizes')}</p>
        ) : (
          <p>
            {t('dashboard.leaderboard.gamification.nextPrice', {
              currentPoints: currentChallengePoints,
              targetPoints: nextPrizePoints,
            })}
          </p>
        )
      ) : null}
      {challengePrizes ? (
        <>
          <Subtitle className="mt-6">
            {t('dashboard.leaderboard.gamification.prizes')}
          </Subtitle>
          {challengePrizes.map((p) => {
            const medalImage = getCorrectMedalImage(p.icon);
            const isCompleted = completedPrizes?.find(
              (cp) => p.minPercent === cp.minPercent
            );

            return (
              <div
                key={`prize-${p.minPercent}`}
                className={`mt-3 ${
                  isCompleted ? 'bg-primary' : 'bg-card'
                } rounded-xl flex-row items-center p-2 flex`}
              >
                <img className="resize-contain w-10 h-10" src={p.prize.image} />
                <div className="flex-1 mx-2">
                  <p
                    className={`${
                      isCompleted ? 'text-white' : ''
                    } font-semibold`}
                  >
                    {p.labels.it}
                  </p>
                  <p className={`${isCompleted ? 'text-white' : ''}`}>
                    {t('dashboard.leaderboard.gamification.quantity')}:{' '}
                    {p.prizeQta}
                  </p>
                </div>
                <img className="h-12 w-12" src={medalImage} />
              </div>
            );
          })}
        </>
      ) : null}

      {currentMetrics && Object.keys(currentMetrics).length > 0 ? (
        <>
          <Subtitle className="mt-4">
            {t('dashboard.leaderboard.gamification.results')}
          </Subtitle>
          <div className="flex-row flex flex-wrap justify-around my-4">
            {Object.keys(currentMetrics).map((metricKey) => {
              const metric = currentMetrics[metricKey];
              return (
                <div key={metricKey} className="w-5/12">
                  <p className="text-xs font-bold">{metric.labels.it}</p>
                  <div className="py-1 px-2 bg-primary w-full mt-1 rounded-xl">
                    <Subtitle className="text-center text-white">
                      {metric.amount}
                    </Subtitle>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CurrentChallengeComponent;
