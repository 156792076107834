import { GetCompanyActionsRequest } from '../../../api/companyAction/companyAction.models';
import { useApi } from '../../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation, useAppQuery } from '../utils';

export const useGetCompanyActions = (
  req: Partial<GetCompanyActionsRequest>
) => {
  const { companyActionApi } = useApi();
  const query = useAppQuery(
    [InvalidationTag.CompanyAction, req],
    ({ queryKey }) => {
      const reqFromKey = queryKey[1] as GetCompanyActionsRequest;
      return companyActionApi.getCompanyActions(reqFromKey);
    },
    {
      enabled: Boolean(req.companyId && req.entityId && req.from && req.to),
    }
  );
  return query;
};

export const useGetCompanyActionsMutation = () => {
  const { companyActionApi } = useApi();
  const mutation = useAppMutation((req: GetCompanyActionsRequest) => {
    return companyActionApi.getCompanyActions(req);
  });
  return mutation;
};
