import React from 'react';
import { FC } from '../types/general.types';

type ParagraphPrimaryProps = {
  className?: string;
};

const ParagraphPrimary: FC<ParagraphPrimaryProps> = ({
  children,
  className,
}) => {
  return <p className={`text-sm text-fake-black ${className}`}>{children}</p>;
};

export default ParagraphPrimary;
