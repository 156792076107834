import { FC } from '../types/general.types';

type ParagraphSecondaryProps = {
  className?: string;
};

const ParagraphSecondary: FC<ParagraphSecondaryProps> = ({
  className,
  children,
}) => {
  return <p className={`text-sm text-grey ${className}`}>{children}</p>;
};

export default ParagraphSecondary;
