export enum CompanyActionLevel {
  Company = 'company',
  Team = 'team',
  User = 'user',
}

export enum CompanyActionType {
  Action = 'action',
  OneToOne = '1to1',
}

export interface CompanyAction {
  pk: string;
  sk: string;
  createdAt: string;
  updatedAt: string;
  companyKey: string;
  companyId: string;
  type: CompanyActionType;
  actionLevel: CompanyActionLevel;
  heading: string;
  body: string;
  implementationDate: string;
  insertedBy: string;
}

export interface CreateCompanyActionRequest {
  companyId: string;
  type: CompanyActionType;
  actionLevel: CompanyActionLevel;
  entityId: string;
  heading: string;
  body: string;
  implementationDate: string;
}

export interface CreateCompanyActionResponse {
  item: CompanyAction;
}

export interface UpdateCompanyActionRequest {
  companyId: string;
  actionId: string;
  heading: string;
  body: string;
  implementationDate: string;
}

export interface UpdateCompanyActionResponse {
  item: CompanyAction;
}

export interface DeleteCompanyActionRequest {
  companyId: string;
  actionId: string;
}

export interface GetCompanyActionsRequest {
  companyId: string;
  entityId: string;
  actionLevel: CompanyActionLevel;
  from: string;
  to: string;
}

export interface GetCompanyActionsResponse {
  items: CompanyAction[];
}
