import * as React from 'react';
import { FeedbackVote } from '../api/feedback/feedback.models';
import { getClassColorFromValue } from '../utils/getClassColorFromValue';

type FeedbackCategoryPillProps = {
  suggestion: string;
  categoryIcon: React.ReactNode;
  value: FeedbackVote;
  isSelected?: boolean;
  className?: string;
};

const FeedbackCategoryPill: React.FC<FeedbackCategoryPillProps> = ({
  suggestion,
  value,
  isSelected,
  className = '',
  categoryIcon,
}) => {
  const selectionColorClass = React.useMemo(() => {
    return getClassColorFromValue(value);
  }, [value]);

  const selectedClasses = isSelected
    ? `bg-${selectionColorClass} border-${selectionColorClass} text-white`
    : 'bg-card border-text-secondary';

  return (
    <div
      title={suggestion}
      className={`rounded-full p-1 ${selectedClasses} ${className}`}
    >
      {categoryIcon}
    </div>
  );
};

export default FeedbackCategoryPill;
