import { useParams } from 'react-router-dom';
import { useGetCompanyLeaderboard } from '../../../hooks/api/gamification.api';
import moment from 'moment';
import { useState } from 'react';

export const useLeaderboardController = () => {
  const { companyId } = useParams();
  const [month, setMonth] = useState(moment());
  const { data: leaderboard } = useGetCompanyLeaderboard(
    companyId,
    month.format('YYYY-MM')
  );

  return {
    leaderboard,
    setMonth,
    month,
  };
};
