import { VotingDay } from './team/teams.models';

export const createNotImplementedMethod = () => {
  return () => {
    throw new Error('Not implemented');
  };
};

export const normalizeVotingDays = (votingDays: VotingDay[]): string => {
  return votingDays.reduce((prev, current, i) => {
    const isFirst = i === 0;
    return `${prev}${isFirst ? '' : ','}${current}`;
  }, '');
};
