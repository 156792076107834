import React, { createContext, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loading } from '../../components';
import { isSomethingLoading } from '../../redux/general/general.selectors';
import { FC } from '../../types/general.types';

export type OpenCallback = () => void;
export type CloseCallback = () => void;

export type LoaderProps = {
  open: OpenCallback;
  close: CloseCallback;
};

export const LoaderContext = createContext<LoaderProps>({
  open: () => {
    throw new Error('No open method assigned to the provider');
  },
  close: () => {
    throw new Error('No close method assigned to the provider');
  },
});

export const LoaderProvider: FC = ({ children }) => {
  const isFullPageLoaderLoading = useSelector(isSomethingLoading);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const open = useCallback(() => {
    setIsLoading(true);
  }, []);

  const close = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        open,
        close,
      }}
    >
      {children}
      {isLoading || isFullPageLoaderLoading ? <Loading /> : null}
    </LoaderContext.Provider>
  );
};
