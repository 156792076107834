import React, { createContext, useCallback, useMemo } from 'react';
import { WebErrorHandler } from '../../services/ErrorHandler/ErrorHandler';
import {
  IErrorHandler,
  NotImplementedErrorHandler,
} from '../../services/ErrorHandler/IErrorHandler';
import { ToastType } from '../ToastContext/ToastContext';
import { useToastNotification } from '../ToastContext/useToastNotification';
import { FC } from '../../types/general.types';

export type ErrorHandlerContextProps = {
  errorHandler: IErrorHandler;
};

export const ErrorHandlerContext = createContext<ErrorHandlerContextProps>({
  errorHandler: new NotImplementedErrorHandler(),
});

export const ErrorHandlerProvider: FC = ({ children }) => {
  const openToast = useToastNotification();

  const openErrorToast = useCallback(
    (message: string) => {
      openToast({
        message,
        type: ToastType.Error,
      });
    },
    [openToast]
  );

  const errorHandler = useMemo(() => {
    return new WebErrorHandler(openErrorToast);
  }, [openErrorToast]);

  return (
    <ErrorHandlerContext.Provider value={{ errorHandler }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
