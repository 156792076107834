import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../context/ApiContext/useApi';
import { setIsAuthorized } from '../redux/general/general.slice';
import { useClearCache } from './api/utils';

export const useSignOut = () => {
  const dispatch = useDispatch();
  const clearCache = useClearCache();
  const { authApi } = useApi();

  const signOut = useCallback(async () => {
    await authApi.revokeToken();
    clearCache();
    dispatch(setIsAuthorized(false));
  }, [authApi, clearCache, dispatch]);

  return signOut;
};
