import * as React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useLanguage } from '../../locale';
import dayjs from 'dayjs';

export type AddCompanyActionForm = {
  title: string;
  description: string;
  implementationDate: string;
};

export default function useAddCompanyActionValidation(
  toEditData?: AddCompanyActionForm
) {
  const [t] = useLanguage();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<AddCompanyActionForm>();

  React.useEffect(() => {
    if (toEditData) {
      setValue('title', toEditData.title);
      setValue(
        'implementationDate',
        dayjs(toEditData.implementationDate).format('YYYY-MM-DD')
      );
      setValue('description', toEditData.description);
    }
  }, [setValue, toEditData]);

  const {
    field: { onChange: onTitleChange, value: titleValue },
  } = useController<AddCompanyActionForm, 'title'>({
    name: 'title',
    control,
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: t('shared.createCompanyActionModal.formErrors.titleRequired'),
      },
      maxLength: {
        value: 40,
        message: t('shared.createCompanyActionModal.formErrors.titleTooLong'),
      },
    },
  });

  const {
    field: { onChange: onDescriptionChange, value: descriptionValue },
  } = useController<AddCompanyActionForm, 'description'>({
    name: 'description',
    control,
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: t(
          'shared.createCompanyActionModal.formErrors.descriptionRequired'
        ),
      },
      maxLength: {
        value: 1500,
        message: t(
          'shared.createCompanyActionModal.formErrors.descriptionTooLong'
        ),
      },
    },
  });

  const {
    field: {
      onChange: onImplementationDateChange,
      value: implementationDateValue,
    },
  } = useController<AddCompanyActionForm, 'implementationDate'>({
    name: 'implementationDate',
    control,
    defaultValue: '',
    rules: {
      maxLength: {
        value: 1500,
        message: t(
          'shared.createCompanyActionModal.formErrors.implementationDateReq'
        ),
      },
    },
  });

  return {
    handleSubmit,
    onTitleChange,
    titleValue,
    onDescriptionChange,
    descriptionValue,
    onImplementationDateChange,
    implementationDateValue,
    reset,
    errors,
  };
}
