import { useContext } from 'react';
import {
  CallbackSetTranslation,
  CallbackTranslation,
  LocalizationContext,
  SupportedLocale,
} from './localizationContext';

export const useLanguage = (): [
  CallbackTranslation,
  CallbackSetTranslation,
  SupportedLocale
] => {
  const { t, setLocale, currentLocale } = useContext(LocalizationContext);
  return [t, setLocale, currentLocale];
};
