import DataTable, { TableStyles } from 'react-data-table-component';
import { useLeaderboardTableColumn } from '../controllers/useLeaderboardTableColumns';
import { useLeaderboardController } from '../controllers/useLeaderboardController';
import { DatePicker } from '@mui/x-date-pickers';
import { Subtitle } from '../../../components';
import moment from 'moment';
import { useLanguage } from '../../../locale';

const tableStyle: TableStyles = {
  responsiveWrapper: {
    style: {
      height: '100%',
    },
  },
};

const LeaderboardTable = () => {
  const { columns } = useLeaderboardTableColumn();
  const { leaderboard, setMonth, month } = useLeaderboardController();
  const [t] = useLanguage();

  if (!leaderboard) return null;

  return (
    <>
      <div className="flex gap-6 mv-6 items-center">
        <Subtitle>{t('dashboard.leaderboard.selectMonth')}</Subtitle>
        <DatePicker
          views={['month', 'year']}
          value={month}
          onChange={(a) => setMonth(a ?? moment())}
        />
      </div>
      <DataTable
        customStyles={tableStyle}
        columns={columns}
        data={leaderboard}
        fixedHeader={true}
        fixedHeaderScrollHeight="100%"
      />
    </>
  );
};

export default LeaderboardTable;
