import React from 'react';
import { VotingDay } from '../api/team/teams.models';
import { useLanguage } from '../locale';
import { getDayTranslation } from '../utils/miscellaneous';
import ParagraphPrimary from './ParagraphPrimary';

const workingDays = [
  VotingDay.Monday,
  VotingDay.Tuesday,
  VotingDay.Wednesday,
  VotingDay.Thursday,
  VotingDay.Friday,
  VotingDay.Saturday,
  VotingDay.Sunday,
];

type WorkingDaysProps = {
  selectedDays: VotingDay[] | undefined;
  onDayClick?: (clickedDay: VotingDay) => void;
};

const WorkingDays: React.FC<WorkingDaysProps> = ({
  selectedDays = [],
  onDayClick,
}) => {
  const [t] = useLanguage();

  return (
    <div className="flex gap-3 flex-wrap justify-center">
      {workingDays.map((wd) => {
        const isSelected = selectedDays.includes(wd);
        const text = getDayTranslation(t, wd).slice(0, 3);
        const textClass = isSelected ? '!text-white' : '';
        const containerClass = isSelected ? 'bg-secondary' : 'bg-card';
        const clickDay = () => onDayClick?.(wd);
        return (
          <button
            onClick={clickDay}
            key={wd}
            className={`py-2 w-16 rounded-lg border-0 ${containerClass}`}
          >
            <ParagraphPrimary
              className={`text-center font-medium ${textClass}`}
            >
              {text}
            </ParagraphPrimary>
          </button>
        );
      })}
    </div>
  );
};

export default WorkingDays;
