import * as React from 'react';

type ChipProps = {
  label: string;
  style?: React.HTMLAttributes<HTMLButtonElement>['style'];
  className?: string;
  onClick?: () => void;
};

const Chip: React.FC<ChipProps> = ({ className, onClick, label, style }) => {
  return (
    <button
      style={style}
      onClick={onClick}
      className={`rounded-full px-2 py-0.5 text-sm ${className}`}
    >
      {label}
    </button>
  );
};

export default Chip;
