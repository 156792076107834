import * as React from 'react';
import DataTable, {
  TableColumn,
  TableStyles,
} from 'react-data-table-component';
import { useLanguage } from '../../locale';
import {
  CompanyAction,
  CompanyActionLevel,
  CompanyActionType,
} from '../../api/companyAction/companyAction.models';
import dayjs from 'dayjs';
import { getCompanyActionLevelTranslation } from '../../utils/getCompanyActionLevelTranslation';
import { useGetCompanyActions } from '../../hooks/api/companyAction/useGetCompanyActions';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import setToMaxHours from '../../utils/setToMaxHours';
import setToMinHours from '../../utils/setToMinHours';
import moment from 'moment';
import ParagraphPrimary from '../ParagraphPrimary';
import Button from '../Button';
import { plusImage } from '../../assets/images';
import { ModalRef } from '../Modal';
import AddCompanyActionModal from './AddCompanyActionModal';
import CompanyActionColumn from './CompanyActionColumn';
import { AddCompanyActionForm } from '../../hooks/validation/useAddCompanyActionValidation';

const AnyDateRangePicker: any = DateRangePicker;

const DEFAULT_FROM = setToMinHours(moment()).subtract(1, 'year');
const DEFAULT_TO = setToMaxHours(moment());

const tableStyle: TableStyles = {
  responsiveWrapper: {
    style: {
      height: '100%',
    },
  },
};

type CompanyActionsTableProps = {
  companyId: string;
  entityId: string;
  actionLevel: CompanyActionLevel;
};

const CompanyActionsTable: React.FC<CompanyActionsTableProps> = ({
  actionLevel,
  companyId,
  entityId,
}) => {
  const [t] = useLanguage();

  const addCompanyActionModalRef = React.useRef<ModalRef>(null);

  const [selectedAction, setSelectedAction] = React.useState<CompanyAction>();
  const [focusedInput, setFocusedInput] =
    React.useState<FocusedInputShape | null>(null);
  const [from, setFrom] = React.useState<moment.Moment | null>(DEFAULT_FROM);
  const [to, setTo] = React.useState<moment.Moment | null>(DEFAULT_TO);

  const { data: actions } = useGetCompanyActions({
    actionLevel,
    companyId,
    entityId,
    from: from ? setToMinHours(from).format('YYYY-MM-DDTHH:mm:ss') : undefined,
    to: to ? setToMaxHours(to).format('YYYY-MM-DDTHH:mm:ss') : undefined,
  });

  const openAddCompanyActionModal = React.useCallback(() => {
    addCompanyActionModalRef.current?.openModal();
  }, []);

  const onCloseCompanyActionModal = React.useCallback(() => {
    setSelectedAction(undefined);
  }, []);

  const columns: TableColumn<CompanyAction>[] = React.useMemo(() => {
    return [
      {
        name: t('shared.companyActionsTable.implementationDate'),
        selector: (row) => row.implementationDate,
        format: (row) => {
          const impDate = dayjs(row.implementationDate).format('DD/MM/YY');
          return <div title={impDate}>{impDate}</div>;
        },
        width: '140px',
      },
      {
        name: t('shared.companyActionsTable.title'),
        selector: (row) => row.heading,
        format: (row) => <div title={row.heading}>{row.heading}</div>,
      },
      {
        name: t('shared.companyActionsTable.actionLevel'),
        width: '200px',
        selector: (row) => row.actionLevel,
        format: (row) => {
          const companyActionLevelTranslation =
            getCompanyActionLevelTranslation(t, row.actionLevel);
          return (
            <div title={companyActionLevelTranslation}>
              {companyActionLevelTranslation}
            </div>
          );
        },
      },
      {
        name: t('shared.companyActionsTable.actionType'),
        width: '160px',
        selector: (row) => row.type,
        format: (row) => {
          const actionTypeTranslation =
            row.type === CompanyActionType.Action
              ? t('shared.companyActionType.action')
              : t('shared.companyActionType.oneToOne');
          return (
            <div title={actionTypeTranslation}>{actionTypeTranslation}</div>
          );
        },
      },
      {
        selector: (row) => row.pk,
        right: true,
        format: (row) => {
          const onView = () => {
            setSelectedAction(row);
            openAddCompanyActionModal();
          };
          return (
            <CompanyActionColumn
              onView={onView}
              actionId={row.pk}
              companyId={companyId}
            />
          );
        },
      },
    ];
  }, [companyId, openAddCompanyActionModal, t]);

  const prevData:
    | (AddCompanyActionForm & {
        actionId: string;
        actionType: CompanyActionType;
        actionLevel: CompanyActionLevel;
      })
    | undefined = React.useMemo(() => {
    return selectedAction
      ? {
          title: selectedAction.heading,
          description: selectedAction.body,
          implementationDate: selectedAction.implementationDate,
          actionId: selectedAction.pk,
          actionType: selectedAction.type,
          actionLevel: selectedAction.actionLevel,
        }
      : undefined;
  }, [selectedAction]);

  const onDatesChange = React.useCallback(
    (dates: {
      startDate: moment.Moment | null;
      endDate: moment.Moment | null;
    }) => {
      setFrom(dates.startDate);
      setTo(dates.endDate);
    },
    []
  );

  const onFocusChange = React.useCallback(
    (newFocusedInput: FocusedInputShape | null) => {
      setFocusedInput(newFocusedInput);
    },
    []
  );

  const validateDay = React.useCallback((day: moment.Moment) => {
    return day.isAfter(DEFAULT_TO);
  }, []);

  return (
    <>
      <div className="my-2 flex items-center">
        <ParagraphPrimary className="mr-4 font-medium text-lg">
          {t('dashboard.manageUser.selectDateRange')}:
        </ParagraphPrimary>
        <AnyDateRangePicker
          withFullScreenPortal
          startDateId="startDate"
          endDateId="endDate"
          isOutsideRange={validateDay}
          startDate={from}
          endDate={to}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={onFocusChange}
        />
        <div className="flex-1" />
        <Button className="w-12 h-12" onClick={openAddCompanyActionModal}>
          <img className="w-5 h-5" src={plusImage} alt="Plus button" />
        </Button>
      </div>
      <DataTable
        customStyles={tableStyle}
        columns={columns}
        data={actions || []}
        fixedHeader={true}
        fixedHeaderScrollHeight="100%"
      />
      <AddCompanyActionModal
        onClose={onCloseCompanyActionModal}
        prevDataAction={prevData}
        ref={addCompanyActionModalRef}
        companyId={companyId}
        actionLevel={prevData?.actionLevel || actionLevel}
        entityId={entityId}
      />
    </>
  );
};

export default CompanyActionsTable;
