import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import {
  GetCompanyFeedbackRequest,
  GetCompanyFeedbackResponse,
  GetDataLakeFeedbacksRequest,
  GetDLFeedbacksResponse,
  GetFeedbacksRequest,
  GetFeedbacksResponse,
  GetFeedbacksStatsRequest,
  GetFeedbacksStatsResponse,
} from './feedback.models';
import { IFeedbackApi } from './IFeedbackApi';

export class FeedbackApi implements IFeedbackApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getCompanyFeedbacks = async (
    req: GetCompanyFeedbackRequest
  ) => {
    const res = await this.httpClient.get<GetCompanyFeedbackResponse>(
      `/core/feedback/company/${req.companyId}`,
      {
        isAuthorized: true,
        params: {
          userId: req.userId,
          from: req.from,
          to: req.to,
        },
      }
    );

    return res.items;
  };

  public readonly getFeedbacks = async ({
    teamId,
    ...params
  }: GetFeedbacksRequest) => {
    const res = await this.httpClient.get<GetFeedbacksResponse>(
      `/core/feedback/team/${teamId}`,
      {
        isAuthorized: true,
        params: {
          ...params,
        },
      }
    );

    return res.items;
  };

  public readonly getDataLakeFeedbacks = async ({
    companyId,
    anonymous,
    from,
    to,
    teamId,
    userId,
    page,
    feedbackValue,
  }: GetDataLakeFeedbacksRequest) => {
    const res = await this.httpClient.get<GetDLFeedbacksResponse>(
      `/core/feedback/list/${companyId}`,
      {
        isAuthorized: true,
        params: {
          anonymous: anonymous ? '1' : '0',
          from,
          to,
          teamId,
          userId,
          page: page ? `${page}` : undefined,
          feedbackValue: feedbackValue ? `${feedbackValue}` : undefined,
        },
      }
    );

    return res;
  };

  public readonly getCompanyStats = async (req: GetFeedbacksStatsRequest) => {
    const res = await this.httpClient.get<GetFeedbacksStatsResponse>(
      `/core/feedback/stats/${req.companyId}`,
      {
        isAuthorized: true,
        params: {
          from: req.from,
          to: req.to,
          entity: req.entity,
          entityId: req.entityId,
        },
      }
    );
    return res.item;
  };
}
