import React from 'react';
import { FC } from '../types/general.types';

type SubtitleProps = {
  className?: string;
};

const Subtitle: FC<SubtitleProps> = ({ className, children }) => {
  return <h3 className={`text-xl font-medium ${className}`}>{children}</h3>;
};

export default Subtitle;
