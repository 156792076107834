import { IErrorHandler } from './IErrorHandler';

export class WebErrorHandler implements IErrorHandler {
  constructor(
    private nonBlockingErrorFunction: (message: string, cb?: () => void) => void
  ) {}

  blockInteraction: (message?: string | undefined, cb?: () => void) => void = (
    message
  ) => {
    window.alert(message);
  };

  nonBlockingError: (message: string, cb?: () => void) => void = (
    message,
    cb
  ) => {
    this.nonBlockingErrorFunction(message, cb);
  };
}
