import React from 'react';
import { Subtitle } from '../../../components';

interface SectionLockedProps {
  title: string;
  description: string;
  cta?: React.ReactNode;
}

const SectionLocked = ({ title, description, cta }: SectionLockedProps) => {
  return (
    <div className="flex-col flex p-2">
      <Subtitle>{title}</Subtitle>
      <p>{description}</p>
      {cta && <div className="mt-4">{cta}</div>}
    </div>
  );
};

export default SectionLocked;
