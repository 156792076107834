import React, { ReactNode, useCallback, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FC } from '../types/general.types';

const UmmyOutsideClickHandler = OutsideClickHandler as any;

type PopoverMenuProps = {
  containerClassName?: string;
  buttonClassName?: string;
  menuClassName?: string;
  buttonLayout: ReactNode;
};

const PopoverMenu: FC<PopoverMenuProps> = ({
  menuClassName,
  buttonClassName,
  containerClassName,
  buttonLayout,
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <div className={`${containerClassName}`}>
      <button
        ref={buttonRef}
        className={`bg-transparent border-0 ${buttonClassName}`}
        onClick={openMenu}
      >
        {buttonLayout}
      </button>
      {isMenuOpen && (
        <div
          className={`absolute shadow-md rounded-md bg-white z-50 ${menuClassName}`}
        >
          <UmmyOutsideClickHandler onOutsideClick={closeMenu}>
            {children}
          </UmmyOutsideClickHandler>
        </div>
      )}
    </div>
  );
};

export default PopoverMenu;
