import { createNotImplementedMethod } from '../utils';
import {
  Feedback,
  FeedbackStat,
  GetCompanyFeedbackRequest,
  GetDataLakeFeedbacksRequest,
  GetDLFeedbacksResponse,
  GetFeedbacksRequest,
  GetFeedbacksStatsRequest,
} from './feedback.models';

export interface IFeedbackApi {
  readonly getCompanyFeedbacks: (
    req: GetCompanyFeedbackRequest
  ) => Promise<Feedback[]>;
  readonly getFeedbacks: (req: GetFeedbacksRequest) => Promise<Feedback[]>;
  readonly getDataLakeFeedbacks: (
    req: GetDataLakeFeedbacksRequest
  ) => Promise<GetDLFeedbacksResponse>;
  readonly getCompanyStats: (
    req: GetFeedbacksStatsRequest
  ) => Promise<FeedbackStat>;
}

export class NotImplementedFeedbackApi implements IFeedbackApi {
  public readonly getCompanyFeedbacks = createNotImplementedMethod();
  public readonly getFeedbacks = createNotImplementedMethod();
  public readonly getDataLakeFeedbacks = createNotImplementedMethod();
  public readonly getCompanyStats = createNotImplementedMethod();
}
