import { Medal } from '../api/gamification/gamification.models';
import { medalBronze, medalGold, medalSilver } from '../assets/images';

export const getCorrectMedalImage = (medalId: string) => {

  switch (medalId) {
    case Medal.Gold:
      return medalGold;
    case Medal.Silver:
      return medalSilver;
    case Medal.Bronze:
      return medalBronze;
    default:
      return medalBronze;
  }
};
