import { useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation } from '../utils';
import { DeleteCompanyActionRequest } from '../../../api/companyAction/companyAction.models';

export const useDeleteCompanyAction = () => {
  const { companyActionApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: DeleteCompanyActionRequest) =>
      companyActionApi.deleteCompanyAction(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyAction],
        });
      },
    }
  );
  return mutation;
};
