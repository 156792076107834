import * as React from 'react';
import Modal, { ModalRef } from '../Modal';
import { useLanguage } from '../../locale';
import Title from '../Title';
import ParagraphSecondary from '../ParagraphSecondary';
import Button, { ButtonType } from '../Button';
import { useDeleteCompanyAction } from '../../hooks/api/companyAction/useDeleteCompanyAction';

type DeleteCompanyActionModalProps = {
  actionId: string;
  companyId: string;
};

const DeleteCompanyActionModal: React.ForwardRefExoticComponent<
  DeleteCompanyActionModalProps & React.RefAttributes<ModalRef>
> = React.forwardRef(({ actionId, companyId }, forwardedRef) => {
  const [t] = useLanguage();
  const modalRef = React.useRef<ModalRef>(null);

  const openModal = React.useCallback(() => {
    modalRef.current?.openModal();
  }, []);

  const closeModal = React.useCallback(() => {
    modalRef.current?.closeModal();
  }, []);

  React.useImperativeHandle(forwardedRef, () => ({
    openModal,
    closeModal,
  }));

  const { mutateAsync: deleteCompanyAction } = useDeleteCompanyAction();

  const onDelete = React.useCallback(async () => {
    try {
      await deleteCompanyAction({ actionId, companyId });
      closeModal();
    } catch {
      // Already handled in root
    }
  }, [actionId, closeModal, companyId, deleteCompanyAction]);

  return (
    <Modal ref={modalRef}>
      <Title>{t('shared.deleteCompanyActionModal.title')}</Title>
      <ParagraphSecondary className="mt-4">
        {t('shared.deleteCompanyActionModal.description')}
      </ParagraphSecondary>
      <div className="mt-8 flex">
        <Button
          type={ButtonType.Secondary}
          onClick={closeModal}
          className="flex-1"
        >
          {t('shared.deleteCompanyActionModal.cancel')}
        </Button>
        <Button
          type={ButtonType.Danger}
          onClick={onDelete}
          className="flex-1 ml-4"
        >
          {t('shared.deleteCompanyActionModal.remove')}
        </Button>
      </div>
    </Modal>
  );
});

export default DeleteCompanyActionModal;
