import dayjs from 'dayjs';
import {
  Challenge,
  Labels,
} from '../../../api/gamification/gamification.models';
import { useLanguage } from '../../../locale';

interface PastChallengeCardProps {
  challenge: Challenge;
}

const PastChallengeCard = ({ challenge }: PastChallengeCardProps) => {
  const [t, , currentLocale] = useLanguage();
  const { targets, id, startsAt, endsAt, targetAmount, currentAmount } =
    challenge;

  const percentage = (currentAmount / targetAmount) * 100;
  const completedPrizes = targets.filter((t) => percentage >= t.minPercent);
  const quantity = completedPrizes.reduce((pv, cv) => pv + cv.prizeQta, 0);

  return (
    <div
      key={id}
      className="bg-card mb-3 py-2  flex flex-row border-primary border-2 rounded-xl items-center"
    >
      <img
        className="resize-contain h-10 w-10 ml-4 mr-6"
        src={`${targets[0]?.prize.image}`}
      />

      <div>
        <p className="text-xs mb-1">
          <span>
            {t('dashboard.leaderboard.gamification.historySection.range')}:{' '}
          </span>
          <span className="font-bold">
            {dayjs(startsAt).format('DD/MM/YY')} -{' '}
            {dayjs(endsAt).format('DD/MM/YY')}
          </span>
        </p>

        <p className="text-xs mb-1">
          <span>
            {t('dashboard.leaderboard.gamification.historySection.prize')}:{' '}
          </span>
          <span className="font-bold">
            {targets[0]?.labels[currentLocale as keyof Labels]}
          </span>
        </p>

        <p className="text-xs mb-1">
          <span>
            {t('dashboard.leaderboard.gamification.historySection.quantity')}:{' '}
          </span>
          <span className="font-bold">{quantity}</span>
        </p>
      </div>
    </div>
  );
};

export default PastChallengeCard;
