import React, { useCallback } from 'react';
import { FC } from '../types/general.types';

type CheckboxProps = {
  className?: string;
  onCheckboxChange?: (newVal: boolean) => void;
  value?: boolean;
};

const Checkbox: FC<CheckboxProps> = ({
  children,
  className,
  value,
  onCheckboxChange,
}) => {
  const toggleSelection = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheckboxChange?.(e.target.checked);
    },
    [onCheckboxChange]
  );

  return (
    <div className={`flex ${className}`}>
      <label className="flex items-center">
        <input
          onChange={toggleSelection}
          checked={value}
          type="checkbox"
          className="form-checkbox bg-card text-primary rounded w-5 h-5"
        />
        {children && <span className="ml-2">{children}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
