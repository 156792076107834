import { useQueryClient } from '@tanstack/react-query';
import {
  AddMemberRequest,
  UpdateTeamStatusRequest,
  CreateTeamRequest,
  GetCompanyTeamDetailsRequest,
  GetCompanyTeamsRequest,
  GetTeamMembersRequest,
  RemoveTeamMemberRequest,
  UpdateTeamRequest,
  SetTeamMemberAsAdminRequest,
  SetTeamMemberAsUserRequest,
  UpdateTeamMemberMetaRequest,
  TeamStatus,
  GetTeamsForUserRequest,
  GetTeamDetailsRequest,
  GetUserTeamsRequest,
} from '../../api/team/teams.models';
import { useApi } from '../../context/ApiContext/useApi';
import {
  InvalidationTag,
  LoaderType,
  useAppMutation,
  useAppQuery,
} from './utils';

export const useGetTeamDetails = (
  req: GetTeamDetailsRequest,
  isEnabled: boolean = true
) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.CompanyTeams, req],
    ({ queryKey }) => {
      const params = queryKey[1] as GetTeamDetailsRequest;
      return teamApi.getTeamDetails(params);
    },
    {
      enabled: isEnabled,
    }
  );
  return queryObject;
};

export const useGetCompanyTeams = (
  companyId: string,
  query?: string,
  status?: TeamStatus | null,
  next?: string | null,
  prev?: string | null
) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.CompanyTeams, { companyId, query, status, next, prev }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetCompanyTeamsRequest;
      return teamApi.getCompanyTeams(params);
    }
  );
  return queryObject;
};

export const useGetCompanyTeamsMutation = () => {
  const { teamApi } = useApi();
  const mutation = useAppMutation(
    async (req: GetCompanyTeamsRequest) => {
      const res = await teamApi.getCompanyTeams(req);
      return res;
    },
    undefined,
    LoaderType.None
  );
  return mutation;
};

export const useCreateTeam = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: CreateTeamRequest) => teamApi.createTeam(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyTeams],
        });
      },
    }
  );
  return mutation;
};

export const useUpdateTeam = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateTeamRequest) => teamApi.updateTeam(req),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyTeams, { teamId: req.teamId }],
        });
      },
    }
  );
  return mutation;
};

export const useUpdateTeamStatus = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateTeamStatusRequest) => teamApi.updateTeamStatus(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyTeams],
        });
      },
    }
  );
  return mutation;
};

export const useAddTeamMember = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: AddMemberRequest) => teamApi.addTeamMember(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useRemoveTeamMember = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: RemoveTeamMemberRequest) => teamApi.removeTeamMember(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useGetTeamMembers = (
  teamId: string | undefined,
  next?: string,
  prev?: string
) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.TeamMembers, { teamId, next, prev }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetTeamMembersRequest;
      if (params.teamId) {
        return teamApi.getTeamMembers({ teamId: params.teamId, next, prev });
      } else {
        return {
          items: [],
          next: null,
          previous: null,
        };
      }
    }
  );
  return queryObject;
};

export const useGetCompanyTeamDetails = (teamId: string) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.CompanyTeams, { teamId }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetCompanyTeamDetailsRequest;
      return teamApi.getCompanyTeamDetails({ teamId: params.teamId });
    }
  );
  return queryObject;
};

export const useSetTeamMemberAsAdmin = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: SetTeamMemberAsAdminRequest) => teamApi.setTeamMemberAsAdmin(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useSetTeamMemberAsUser = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: SetTeamMemberAsUserRequest) => teamApi.setTeamMemberAsUser(req),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers, { teamId: variables.teamId }],
        });
      },
    }
  );
  return mutation;
};

export const useUpdateTeamMemberMeta = () => {
  const { teamApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateTeamMemberMetaRequest) => teamApi.updateTeamMemberMeta(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useGetTeamsForUser = (
  { userId, admin }: Partial<GetTeamsForUserRequest>,
  isEnabled: boolean = true
) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.CompanyTeams, { userId, admin }],
    ({ queryKey }) => {
      const params = queryKey[1] as { userId: string; admin?: 0 | 1 };
      if (params.userId) {
        return teamApi.getTeamsForUser(params);
      } else {
        throw new Error('User id is undefined on GetTeamForUser api call');
      }
    },
    {
      enabled: isEnabled,
    }
  );
  return queryObject;
};

export const useGetUserTeams = (
  { userId }: Partial<GetUserTeamsRequest>,
  isEnabled: boolean = true
) => {
  const { teamApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.CompanyTeams, { userId }],
    ({ queryKey }) => {
      const params = queryKey[1] as { userId: string };
      if (params.userId) {
        return teamApi.getTeamsForUser(params);
      } else {
        throw new Error('User id is undefined on GetTeamForUser api call');
      }
    },
    {
      enabled: isEnabled,
    }
  );
  return queryObject;
};
