import React from 'react';
import Button from './Button';
import { FC } from '../types/general.types';

type SecondaryButtonProps = {
  className?: string;
  onClick?: () => void;
};

const SecondaryButton: FC<SecondaryButtonProps> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <Button
      className={`bg-secondary border-secondary ${className}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
