export interface IErrorHandler {
  readonly blockInteraction: (message?: string, callback?: () => void) => void;
  readonly nonBlockingError: (message: string, callback?: () => void) => void;
}

export class NotImplementedErrorHandler implements IErrorHandler {
  public readonly blockInteraction = () => {
    throw new Error('blockInteraction is not implemented');
  };
  public readonly nonBlockingError = () => {
    throw new Error('blockInteraction is not implemented');
  };
}
