import {
  GetAchievementsRequest,
  GetChallengeRequest,
  GetCompanyLeaderboardRequest,
  GetHistoryChallengesRequest,
} from '../../api/gamification/gamification.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppQuery } from './utils';

export const useGetCompanyLeaderboard = (
  companyId: string | undefined,
  month: string | undefined
) => {
  const { gamificationApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.Leaderboard, { companyId, month }],
    ({ queryKey }) => {
      const params = queryKey[1] as GetCompanyLeaderboardRequest;
      return gamificationApi.getCompanyLeaderboard(params);
    },
    {
      enabled: !!companyId,
    }
  );
  return queryObject;
};

export const useGetChallenge = (req: Partial<GetChallengeRequest>) => {
  const { gamificationApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.Challenge, req],
    ({ queryKey }) => {
      const params = queryKey[1] as GetChallengeRequest;
      return gamificationApi.getChallenge(params);
    },
    {
      enabled: Boolean(req.companyId && req.datetime),
    }
  );
  return queryObject;
};

export const useGetChallengesHistory = (
  req: Partial<GetHistoryChallengesRequest>
) => {
  const { gamificationApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.Challenge, req],
    ({ queryKey }) => {
      const params = queryKey[1] as GetHistoryChallengesRequest;
      return gamificationApi.getHistoryChallenges(params);
    },
    {
      enabled: Boolean(req.companyId),
    }
  );
  return queryObject;
};

export const useGetAchievements = (req: Partial<GetAchievementsRequest>) => {
  const { gamificationApi } = useApi();
  const queryObject = useAppQuery(
    [InvalidationTag.Achievements, req],
    ({ queryKey }) => {
      const params = queryKey[1] as GetAchievementsRequest;
      return gamificationApi.getAchievements(params);
    },
    {
      enabled: Boolean(req.companyId),
    }
  );
  return queryObject;
};
