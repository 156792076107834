import React from 'react';

export type ButtonToggleElement<T> = {
  key: T;
  label: string;
};

type ButtonToggleProps<T> = {
  elements: ButtonToggleElement<T>[];
  activeElementKey?: T;
  onChange?: (key: T) => void;
};

const ButtonToggle = <T,>({
  onChange,
  elements,
  activeElementKey,
}: ButtonToggleProps<T>) => {
  return (
    <div className="bg-card p-2 rounded-lg flex">
      {elements.map((e, i) => {
        const isActive = e.key === activeElementKey;
        const textColorClass = isActive ? 'text-white' : 'text-fake-black';
        const bgColorClass = isActive ? 'bg-primary' : '';
        const marginClass = i !== 0 ? 'ml-2' : '';
        return (
          <button
            key={`button-toggle-${e.key}`}
            className={`flex-1 h-full rounded-md text-center font-medium flex py-3 justify-center align-middle ${textColorClass} ${bgColorClass} ${marginClass}`}
            onClick={() => onChange?.(e.key)}
          >
            {e.label}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonToggle;
