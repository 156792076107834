import React from 'react';
import AuthNavbar, { AuthNavbarProps } from './AuthNavbar';
import Card from './Card';
import { FC } from '../types/general.types';

type AuthLayoutProps = {} & AuthNavbarProps;

const SmallLayout: FC<AuthLayoutProps> = ({
  children,
  leftButton,
  rightButton,
}) => {
  return (
    <div className="mx-auto py-6 max-w-md px-4">
      <AuthNavbar leftButton={leftButton} rightButton={rightButton} />
      <Card>{children}</Card>
    </div>
  );
};

export default SmallLayout;
