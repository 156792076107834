import { createNotImplementedMethod } from '../utils';
import {
  AddMemberRequest,
  UpdateTeamStatusRequest,
  CreateTeamRequest,
  GetCompanyTeamDetailsRequest,
  GetCompanyTeamsRequest,
  GetTeamMembersRequest,
  RemoveTeamMemberRequest,
  Team,
  UpdateTeamRequest,
  SetTeamMemberAsAdminRequest,
  SetTeamMemberAsUserRequest,
  UpdateTeamMemberMetaRequest,
  TeamUser,
  GetCompanyTeamsResponse,
  GetTeamMembersResponse,
  GetTeamsForUserRequest,
  GetTeamsForUserResponse,
  GetTeamDetailsRequest,
  GetUserTeamsRequest,
} from './teams.models';

export interface ITeamApi {
  readonly getTeamDetails: (params: GetTeamDetailsRequest) => Promise<Team>;
  readonly getCompanyTeams: (
    params: GetCompanyTeamsRequest
  ) => Promise<GetCompanyTeamsResponse>;
  readonly createTeam: (params: CreateTeamRequest) => Promise<Team>;
  readonly updateTeam: (params: UpdateTeamRequest) => Promise<Team>;
  readonly updateTeamStatus: (params: UpdateTeamStatusRequest) => Promise<void>;
  readonly addTeamMember: (params: AddMemberRequest) => Promise<TeamUser>;
  readonly removeTeamMember: (params: RemoveTeamMemberRequest) => Promise<void>;
  readonly getTeamMembers: (
    params: GetTeamMembersRequest
  ) => Promise<GetTeamMembersResponse>;
  readonly getCompanyTeamDetails: (
    params: GetCompanyTeamDetailsRequest
  ) => Promise<Team>;
  readonly setTeamMemberAsAdmin: (
    params: SetTeamMemberAsAdminRequest
  ) => Promise<void>;
  readonly setTeamMemberAsUser: (
    params: SetTeamMemberAsUserRequest
  ) => Promise<void>;
  readonly updateTeamMemberMeta: (
    params: UpdateTeamMemberMetaRequest
  ) => Promise<void>;
  readonly getTeamsForUser: (
    params: GetTeamsForUserRequest
  ) => Promise<GetTeamsForUserResponse>;
  readonly getUserTeams: (params: GetUserTeamsRequest) => Promise<Team[]>;
}

export class NotImplementedTeamApi implements ITeamApi {
  public readonly getTeamDetails = createNotImplementedMethod();
  public readonly getCompanyTeams = createNotImplementedMethod();
  public readonly createTeam = createNotImplementedMethod();
  public readonly updateTeam = createNotImplementedMethod();
  public readonly updateTeamStatus = createNotImplementedMethod();
  public readonly addTeamMember = createNotImplementedMethod();
  public readonly removeTeamMember = createNotImplementedMethod();
  public readonly getTeamMembers = createNotImplementedMethod();
  public readonly getCompanyTeamDetails = createNotImplementedMethod();
  public readonly setTeamMemberAsAdmin = createNotImplementedMethod();
  public readonly setTeamMemberAsUser = createNotImplementedMethod();
  public readonly updateTeamMemberMeta = createNotImplementedMethod();
  public readonly getTeamsForUser = createNotImplementedMethod();
  public readonly getUserTeams = createNotImplementedMethod();
}
