import { Subtitle } from '../../../components';
import { useLanguage } from '../../../locale';
import useChallengeSectionController from '../controllers/useCurrentChallengeController';
import CurrentChallengeComponent from './CurrentChallengeComponent';
import PastChallengesSection from './PastChallengesSection';

const ChallengeSection = () => {
  const { challengeExist, historyExist, challenges, historyMetrics } =
    useChallengeSectionController();
  const [t] = useLanguage();

  return (
    <div className="flex flex-row h-[50%] gap-2">
      <div className="flex-col flex flex-1 p-4">
        <Subtitle className="mb-2">
          {t('dashboard.leaderboard.gamification.current')}
        </Subtitle>
        {challengeExist ? (
          <CurrentChallengeComponent />
        ) : (
          <p className="text-grey">
            {t('dashboard.leaderboard.gamification.noChallenge')}
          </p>
        )}
      </div>
      <div className="w-[2px] bg-card " />
      <div className="flex-col flex flex-1 p-4">
        {historyExist ? (
          <PastChallengesSection
            historyMetrics={historyMetrics}
            challenges={challenges ?? []}
          />
        ) : (
          <>
            <Subtitle className="mb-2">
              {t('dashboard.leaderboard.gamification.history')}
            </Subtitle>
            <p className="text-grey">
              {t('dashboard.leaderboard.gamification.noHistory')}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ChallengeSection;
