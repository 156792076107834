import { useQueryClient } from '@tanstack/react-query';
import {
  AddUserToCompanyRequest,
  CreateCompanyRequest,
  GetCompanyRequest,
  GetMemberByIdRequest,
  PromoteUserRequest,
  RemoveUserFromCompanyRequest,
  UpdateCompanyRequest,
} from '../../api/company/companies.models';
import { useApi } from '../../context/ApiContext/useApi';
import {
  InvalidationTag,
  LoaderType,
  useAppMutation,
  useAppQuery,
} from './utils';

export const useGetMyCompanies = (isAdmin: boolean) => {
  const { companyApi } = useApi();
  const queryRes = useAppQuery([InvalidationTag.Company], () =>
    companyApi.getMyCompanies({ admin: isAdmin ? '1' : '0' })
  );
  return queryRes;
};

export const useGetCompany = (companyId: string) => {
  const { companyApi } = useApi();
  const queryRes = useAppQuery(
    [InvalidationTag.Company, { companyId }],
    ({ queryKey }) => {
      const { companyId } = queryKey[1] as GetCompanyRequest;
      return companyApi.getCompany({ companyId });
    }
  );
  return queryRes;
};

export const useEditCompanyMutation = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const queryRes = useAppMutation(
    (req: UpdateCompanyRequest) => {
      return companyApi.editCompany(req);
    },
    {
      onSuccess: (_, { companyId }) => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.Company, { companyId }],
        });
      },
    }
  );
  return queryRes;
};

export const useCreateCompany = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    ({ name, license }: CreateCompanyRequest) =>
      companyApi.createCompany({ name, license }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [InvalidationTag.Company] });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsers],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyTeams],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsersSearch],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.TeamMembers],
        });
      },
    }
  );
  return mutation;
};

export const useGetCompanyUsers = (
  companyId?: string,
  limit?: number,
  next?: string,
  prev?: string
) => {
  const { companyApi } = useApi();
  const query = useAppQuery(
    [InvalidationTag.CompanyUsers, companyId, limit, next, prev],
    ({ queryKey }) => {
      const companyId = queryKey[1];
      const limit = queryKey[2];
      const next = queryKey[3];
      const prev = queryKey[4];

      return companyApi.getCompanyUsers({
        companyId: `${companyId}`,
        limit: limit ? +limit : undefined,
        next: next ? `${next}` : undefined,
        previous: prev ? `${prev}` : undefined,
      });
    },
    {
      structuralSharing: false,
    }
  );
  return query;
};

export const useGetMemberById = (req: Partial<GetMemberByIdRequest>) => {
  const { companyApi } = useApi();
  const query = useAppQuery(
    [InvalidationTag.CompanyUser, req],
    ({ queryKey }) => {
      const { companyId, userId } =
        queryKey[1] as Partial<GetMemberByIdRequest>;
      if (companyId && userId) {
        return companyApi.getMemberById({ companyId, userId });
      }
    }
  );
  return query;
};

export const useSearchCompanyUsers = (companyId: string, query: string) => {
  const { companyApi } = useApi();
  const queryReturn = useAppQuery(
    [InvalidationTag.CompanyUsersSearch, companyId, query],
    ({ queryKey }) => {
      const companyId = queryKey[1];
      const queryValue = queryKey[2];

      return companyApi.searchUsers({
        companyId: `${companyId}`,
        query: queryValue,
      });
    },
    {
      enabled: false,
    }
  );
  return queryReturn;
};

export const useSearchCompanyUsersMutation = () => {
  const { companyApi } = useApi();
  const mutation = useAppMutation(
    (params: { companyId: string; query: string }) =>
      companyApi.searchUsers({
        companyId: `${params.companyId}`,
        query: params.query,
      }),
    undefined,
    LoaderType.None
  );
  return mutation;
};

export const useRemoveCompanyUser = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: RemoveUserFromCompanyRequest) =>
      companyApi.removeUserFromCompany(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsers],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsersSearch],
        });
      },
    }
  );
  return mutation;
};

export const usePromoteToAdmin = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: PromoteUserRequest) => companyApi.promoteUserToAdmin(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsers],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsersSearch],
        });
      },
    }
  );
  return mutation;
};

export const usePromoteToUser = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: PromoteUserRequest) => companyApi.promoteUserToUser(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsers],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsersSearch],
        });
      },
    }
  );
  return mutation;
};

export const useAddUserToCompany = () => {
  const { companyApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: AddUserToCompanyRequest) => companyApi.addUserToCompany(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsers],
        });
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.CompanyUsersSearch],
        });
      },
    }
  );
  return mutation;
};
