import {
  configureStore as configureReduxStore,
  Middleware as ReduxMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { generalReducer, GeneralState } from './general/general.slice';

export const APP_START = '@@APP_START';

export type RootState = {
  general: GeneralState;
};

export type Middleware<DS = {}> = ReduxMiddleware<{}, DS & RootState>;
export type Dispatch = ReturnType<typeof configureStore>['dispatch'];

export type ConfigureStoreParams = {
  middlewares?: Middleware[];
  enhancer?: StoreEnhancer;
};

export const configureStore = ({
  middlewares = [],
  enhancer,
}: ConfigureStoreParams) => {
  const store = configureReduxStore({
    reducer: {
      general: generalReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middlewares),
    enhancers: enhancer ? [enhancer] : [],
  });
  return store;
};

export const store = configureStore({});
