import React from 'react';

type StepperProps = {
  className?: string;
  stepsCount: number;
  currentStep: number;
};

const Stepper: React.FC<StepperProps> = ({
  currentStep,
  stepsCount,
  className,
}) => {
  return (
    <div className="flex w-full">
      {new Array(stepsCount).fill(undefined).map((_, i) => {
        const isFirst = i === 0;
        const isPassed = i < currentStep;
        const isCurrent = i === currentStep;
        const isFuture = !isPassed && !isCurrent;

        return (
          <React.Fragment key={i}>
            <div
              className={`${
                !isFirst ? 'ml-3' : ''
              } h-2 flex-1 rounded-full border-solid border ${
                isPassed ? 'bg-secondary border-secondary' : ''
              } ${isCurrent ? 'border-secondary bg-card' : ''} ${
                isFuture ? 'border-card bg-card' : ''
              } ${className}`}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
