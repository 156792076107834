import React, { useCallback, useImperativeHandle, useRef } from 'react';
import { ModalRef } from '../components';

export const useModalRef = (forwardedRef: React.ForwardedRef<ModalRef>) => {
  const modalRef = useRef<ModalRef>(null);

  const openModal = useCallback(() => {
    modalRef.current?.openModal();
  }, []);

  const closeModal = useCallback(() => {
    modalRef.current?.closeModal();
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    openModal,
    closeModal,
  }));

  return {
    modalRef,
    openModal,
    closeModal,
  };
};
