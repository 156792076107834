import { IHttpClient } from '../../services/HttpClient/IHttpClient';
import { IUserApi } from './IUserApi';
import {
  GetWhoAmIRequest,
  GetWhoAmIResponse,
  UpdateProfileRequest,
  UserDetails,
} from './user.models';

export class UserApi implements IUserApi {
  constructor(private readonly httpClient: IHttpClient) {}

  public readonly getWhoAmI = (req: GetWhoAmIRequest) => {
    return this.httpClient.get<GetWhoAmIResponse>('/user/who-am-i', {
      params: {
        email: req.email,
      },
      isAuthorized: false,
    });
  };

  public readonly getMy = () => {
    return this.httpClient.get<UserDetails>('/user/my', {
      isAuthorized: true,
    });
  };

  public readonly updateMy = (req: UpdateProfileRequest) => {
    return this.httpClient.put<void>('/user/my', {
      isAuthorized: true,
      body: req,
    });
  };
}
