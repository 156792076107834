import React, { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';

export type TextInputProps = {
  value?: string;
  error?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  onValueChange?: (val: string) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: () => void;
  disableEnter?: boolean;
};

const TextInput: React.FC<TextInputProps> = ({
  className,
  placeholder,
  error,
  value,
  isDisabled,
  onChange,
  onValueChange,
  onFocus,
  onBlur,
  disableEnter,
  onEnter,
  type,
}) => {
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      onValueChange?.(e.target.value);
    },
    [onChange, onValueChange]
  );

  return (
    <>
      <input
        disabled={isDisabled}
        value={value}
        placeholder={placeholder}
        type={type}
        className={`bg-card border-0 rounded-md p-3 placeholder-grey text-fake-black outline-0 ${className}`}
        onChange={onInputChange}
        onFocus={onFocus}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !disableEnter) onEnter?.();
        }}
        onBlur={onBlur}
      />
      {error && <p className="text-danger text-sm mt-1">{error}</p>}
    </>
  );
};

export default TextInput;
