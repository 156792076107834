import React from 'react';
import {
  SmallLayout,
  Button,
  ParagraphSecondary,
  TextButton,
  TextInput,
  Title,
} from '../../components';
import { useLanguage } from '../../locale';
import { useAuthCodeController } from './controllers/useAuthCodeController';

type AuthCodePageProps = {};

const AuthCodePage: React.FC<AuthCodePageProps> = () => {
  const [t] = useLanguage();

  const { code, resendEmail, setCode, verifyCode, email } =
    useAuthCodeController();

  return (
    <SmallLayout>
      <Title>{t('authCode.title')}</Title>
      <ParagraphSecondary className="my-4">
        {t('authCode.description', { email })}{' '}
        <TextButton onClick={resendEmail}>
          {t('authCode.resendCode')}
        </TextButton>
      </ParagraphSecondary>
      <TextInput
        className="w-full mt-4"
        type="number"
        value={code}
        onValueChange={setCode}
        placeholder={t('authCode.codePlaceholder')}
      />
      <Button onClick={verifyCode} className="mt-10 w-full">
        {t('authCode.confirm')}
      </Button>
    </SmallLayout>
  );
};

export default AuthCodePage;
