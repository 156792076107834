import React from 'react';
import { useRoutes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import authenticationRoutes from './authentication.routes';
import dashboardRoutes from './dashboard.routes';
import profileRoutes from './profile.routes';

type GeneralRoutesProps = {};

const GeneralRoutes: React.FC<GeneralRoutesProps> = () => {
  const routes = useRoutes([
    ...authenticationRoutes,
    ...dashboardRoutes,
    ...profileRoutes,
  ]);

  return (
    <>
      <AnimatePresence mode="wait">{routes}</AnimatePresence>
    </>
  );
};

export default GeneralRoutes;
