import { useQueryClient } from '@tanstack/react-query';
import { UpdateProfileRequest } from '../../api/user/user.models';
import { useApi } from '../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation, useAppQuery } from './utils';

export const useGetWhoAmI = () => {
  const { userApi } = useApi();
  const mutation = useAppMutation((email: string) =>
    userApi.getWhoAmI({ email })
  );
  return mutation;
};

export const useGetMyUser = () => {
  const { userApi } = useApi();
  const query = useAppQuery([InvalidationTag.MyUser], () => userApi.getMy());
  return query;
};

export const useUpdateProfileMutation = () => {
  const { userApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateProfileRequest) => userApi.updateMy(req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [InvalidationTag.MyUser],
        });
      },
    }
  );
  return mutation;
};
