import React, { useCallback, useState } from 'react';
import ParagraphPrimary from './ParagraphPrimary';
import TextInput, { TextInputProps } from './TextInput';
import OutsideClickHandler from 'react-outside-click-handler';

const AnyOutsideClickHandler: any = OutsideClickHandler;

type TextInputAutocompleteProps<SuggestionType> = {
  suggestions?: SuggestionType[];
  displayFn?: (value: SuggestionType) => string;
  onSuggestionPress?: (suggestion: SuggestionType) => void;
  containerClassName?: string;
  textInputClassName?: string;
} & TextInputProps;

const TextInputAutocomplete = <SuggestionType,>({
  suggestions,
  displayFn,
  onSuggestionPress,
  className,
  containerClassName,
  textInputClassName,
  onBlur,
  onFocus,
  ...props
}: TextInputAutocompleteProps<SuggestionType>) => {
  const [isSuggestionShown, setIsSuggestionShown] = useState(false);

  const onInputBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  const onInputFocus = useCallback(() => {
    onFocus?.();
    setIsSuggestionShown(true);
  }, [onFocus]);

  const onClickOutside = useCallback(() => {
    setIsSuggestionShown(false);
  }, []);

  return (
    <AnyOutsideClickHandler onOutsideClick={onClickOutside}>
      <div className={`relative ${className}`}>
        <TextInput
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          className={textInputClassName}
          {...props}
        />
        {isSuggestionShown && suggestions && suggestions.length > 0 && (
          <div
            className={`absolute top-14 rounded-md z-20 p-2 bg-card shadow-md w-full ${containerClassName}`}
          >
            {suggestions?.map((el, i) => {
              const suggestionPress = () => {
                setIsSuggestionShown(false);
                onSuggestionPress?.(el);
              };
              return (
                <React.Fragment key={i}>
                  <button
                    onClick={suggestionPress}
                    className="p-0 py-1 m-0 border-0 bg-transparent w-full text-left"
                  >
                    <ParagraphPrimary>{displayFn?.(el)}</ParagraphPrimary>
                  </button>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </AnyOutsideClickHandler>
  );
};

export default TextInputAutocomplete;
