import { useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../context/ApiContext/useApi';
import { InvalidationTag, useAppMutation } from '../utils';
import { UpdateCompanyActionRequest } from '../../../api/companyAction/companyAction.models';

export const useUpdateCompanyAction = () => {
  const { companyActionApi } = useApi();
  const queryClient = useQueryClient();
  const mutation = useAppMutation(
    (req: UpdateCompanyActionRequest) =>
      companyActionApi.updateCompanyAction(req),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.invalidateQueries({
            queryKey: [InvalidationTag.CompanyAction],
          });
        }, 1000);
      },
    }
  );
  return mutation;
};
